<template>
  <div class="NewHomebox">
   <NewHome />
  </div>
</template>
<script>
import NewHome from "@/components/NewHome.vue";
export default {
  components: { NewHome },
  data() {
    return {
       
    };
  },
};
</script>
<style lang="scss" scoped>
.NewHomebox{
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: #efefef;
  overflow-y: auto;
}
</style>