<template>
  <div>
    <Header />
    <!-- banner -->
    <div @click="online_advice()">
      <img src="../img/Ai/banner.png" alt="" width="100%" />
    </div>
    <!-- 表单模块 -->
    <div class="cleadox"></div>
    <div class="Fromebox">
      <div class="zihaobig">
        <span>工商局审核通过的公司名才能使用！</span>
      </div>
      <div class="kelibox">
        <div>今日核名<span>{{rs}}</span> 次</div>
        <div>累计核名<span>{{sl}}</span> 次</div>
      </div>
      <div class="dzname">
        <!-- <span>广州</span> -->
        <!-- <van-field v-model="qy" name="区域" placeholder="广州"  /> -->
      </div>
      <van-form @submit="onSubmit">
        <van-field v-model="qy" name="区域" placeholder="广州"  />
        <van-field v-model="username" name="字号" placeholder="请输入您的字号  如：智远"
          :rules="[{ required: true, message: '请输入您的字号  如：智远' }]" />
        <van-field v-model="password" name="行业" placeholder="请输入您的行业表述  如：视觉科技" :rules="[
          { required: true, message: '请输入您的行业表述  如：视觉科技' },
        ]" />
         <van-field v-model="gslx" name="区域" placeholder="有限公司"  />
        <!-- <div class="dzname">
       
          <van-field v-model="gslx" name="区域" placeholder="有限公司"  />
        </div> -->
        <div style="margin: 16px">
          <van-button round block type="danger" native-type="submit">免费查询</van-button>
          <span class="jiyu">使用免费查询功能，该查询功能即时同步官方工商核名系
            统,所查的结果为工商核名系统查询结果</span>
        </div>
      </van-form>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 查询结果 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/Ai/Ticon02.png" alt="" width="25px" height="25px" />
      <span>查询结果</span>
    </div>
    <div class="chaxunbigbox">
      <div class="JGbox">
        您查询的<span> {{textName}}</span> 的情况如下面详情
      </div>
      <div>
        <div class="cxtitlebox">
          <span>与 <em>{{djbs}}</em> 相同的字号已被注册情况</span>
          <span>已查询 <em>{{z_xtym}}</em> 个结果</span>
        </div>
        <div class="cxlists1" v-if="xt_list.length==0">
          <div class="cxtitleboxaaa">
            <span>广州市越秀区 <em>智远</em> 智远机械设备经营部</span>
            <span>2007-01-31</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州 <em>智远</em> 视觉科技有限公司</span>
            <span>2019-03-22</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州 <em>智远</em> 投资咨询有限公司</span>
            <span>2017-04-27</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州 <em>智远</em> 同耀营销策划有限公司</span>
            <span>2019-04-24</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州 <em>智远</em> 信息技术服务有限公司</span>
            <span>2017-05-15</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州市 <em>智远</em> 创达专利代理有限公司</span>
            <span>2019-05-16</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州 <em>智远</em> 投资管理有限公司</span>
            <span>2017-05-31</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州市 <em>智远</em> 置业有限公司</span>
            <span>2019-05-30</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州恒星 <em>智远</em> 科技咨询有限公司</span>
            <span>2019-06-12</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州市 <em>智远</em> 物业管理有限公司</span>
            <span>2019-06-26</span>
          </div>
        </div>
        <div class="cxlists1" v-else>
          <div class="cxtitleboxaaa" v-for="(item,index) in xt_list" :key="index">
            <span class="spanleft" v-if="item.gs_name" v-html="showDate(item.gs_name)"></span>
            <span class="spanleft" v-else="item.gs_name==''" v-html="showDate(item.name)"></span>
            <span>{{item.creater_at}}</span>
          </div>
        </div>
        
        <div style="margin: 20px 0">
          <!-- <van-button round block type="danger" style="margin-top: 20px">更多查询</van-button> -->
          <van-pagination v-model="xtym" :total-items="z_xtym" @change="xtym_hqym()"/>
        </div>
         <!-- 点击拨打电话 -->
        <div class="call" @click="Botell">
          <img src="../assets/call.png" alt="" width="310">
        </div>
      </div>
      <div>
        <div class="cxtitlebox">
          <span>含<em>{{zh_zh_list[0]}}</em> 或<em>{{zh_zh_list[1]}}</em> 相似的字号已被注册</span>
          <span>已查询 <em>{{z_xsym}}</em> 个结果</span>
        </div>
        <div class="cxlists1" v-if="xs_list.length==0">
          <div class="cxtitleboxaaa">
            <span>广东长 <em>远</em> 投资有限公司</span>
            <span>2003-03-10</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广东博 <em>远</em> 投资有限公司</span>
            <span>2003-03-10</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广东 <em>远</em> 泰投资有限公司</span>
            <span>2004-05-17</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广东博 <em>智</em> 恒利资产管理有限公司</span>
            <span>2005-06-13</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广东天 <em>智</em> 创展投资有限公司</span>
            <span>2006-05-09</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广东 <em>智</em> 爱健康医生集团有限公司</span>
            <span>2017-07-07</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州能 <em>远</em> 机电设备工程有限公司</span>
            <span>2012-08-08</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州市广安 <em>智</em> 能安防科技有限公司</span>
            <span>2012-09-10</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广州市敦 <em>智</em> 珠宝首饰有限公司</span>
            <span>2012-09-12</span>
          </div>
          <div class="cxtitleboxaaa">
            <span>广东创 <em>智</em> 投资有限公司</span>
            <span>2012-09-10</span>
          </div>
        </div>
        <div class="cxlists1" v-else>
          <div class="cxtitleboxaaa" v-for="(item,index) in xs_list" :key="index">
            <span class="spanleft" v-if="item.gs_name" v-html="xsshowDate(item.gs_name)"></span>
            <span class="spanleft" v-else="item.gs_name==''" v-html="xsshowDate(item.name)"></span>
            <span>{{item.creater_at}}</span>
          </div>
        </div>
        <div style="margin: 20px 0">
          <!-- <van-button round block type="danger" style="margin-top: 20px">更多查询</van-button> -->
          <van-pagination v-model="xsym" :total-items="z_xsym" @change="xsym_hqym()"/>
        </div>
         <!-- 点击拨打电话 -->
        <div class="call" @click="Botell">
          <img src="../assets/call.png" alt="" width="310">
        </div>
      </div>
      <!-- AI推荐 -->
      <div>
        <div class="cxtitlebox">
          <span>公司字号AI推荐</span>
          <span>已查询 <em>{{z_AIym}}</em> 个结果</span>
        </div>
        <div>
          <!-- @search="onSearch"  @click="onSearch"-->
          <van-search v-model="AI_gjz" show-action placeholder="请输入关键词  如：智远" >
            <template #action>
              <div 
              @click="AItj1()"
              style="
                  background-color: red;
                  padding: 0 10px;
                  border-radius: 5px;
                  color: #fff;
                ">
                搜索
              </div>
            </template>
          </van-search>
        </div>
        <div>
          <span>字号长度：</span>
          <div class="biaoqianbox">
            <van-tag type="danger " size="medium" @click="Ai_zs('不限')">不限</van-tag>
            <van-tag type="danger " size="medium" @click="Ai_zs('2个')">2个</van-tag>
            <van-tag type="danger " size="medium" @click="Ai_zs('3个')">3个</van-tag>
            <van-tag type="danger " size="medium" @click="Ai_zs('4个')">4个</van-tag>
          </div>
          <div style="padding-top:10px">
            <div class="aitj" v-for="(item,index) in AI_list" :key="index" >
              <span><em @click="$router.push('/home/Result?name='+item.name)">{{item.name}}</em> 该字号已注册<em @click="$router.push('/home/Result?name='+item.name)">（{{item.total}}）</em> 家</span>
            </div>
          </div>
           <div style="margin: 20px 0">
          <!-- <van-button round block type="danger" style="margin-top: 20px">更多查询</van-button> -->
          <van-pagination v-model="AIym" :total-items="z_AIym" @change="AIym_hqym()"/>
        </div>
         <!-- 点击拨打电话 -->
        <div class="call" @click="Botell">
          <img src="../assets/call.png" alt="" width="310">
        </div>
        <span style="display:block;margin:10px auto; color: red;
    text-align: center;">* 本站数据仅供参考，结果来源企业大数据智能分析</span>
        </div>
      </div>
    </div>
    <!-- 公司核名要注意哪些事？ -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>公司核名要注意哪些事？</span>
    </div>
    <div class="shiitembigbox">
      <div class="shiitembox" v-for="item in SQlists" :key="item.id">
        <div class="left">
          <img :src="item.image" alt="" width="35" />
        </div>
        <div class="right">
          <em>{{ item.title }}</em>
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
     <!-- 点击拨打电话 -->
        <div class="call" @click="Botell">
          <img src="../assets/call.png" alt="" width="310">
        </div>
    <!-- 服务流程 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>服务流程</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Ai/tu01.png" alt="" width="350" />
      </div>
    </div>
     <!-- 点击拨打电话 -->
        <div class="call" @click="Botell">
          <img src="../assets/call.png" alt="" width="310">
        </div>
    <!-- 我们还将提供一站式公司注册服务 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>我们还将提供一站式公司注册服务</span>
    </div>
    <div class="fanweibox">
      <van-grid :column-num="3" :border="false">
        <van-grid-item>
          <img src="../img/Ai/icon01.png" alt="" width="60" />
          <span>公司起名</span>
        </van-grid-item>
        <van-grid-item>
          <img src="../img/Ai/icon02.png" alt="" width="60" />
          <span>确定注册地址</span>
        </van-grid-item>
        <van-grid-item>
          <img src="../img/Ai/icon03.png" alt="" width="60" />
          <span>提交公司资料</span>
        </van-grid-item>
        <van-grid-item>
          <img src="../img/Ai/icon04.png" alt="" width="60" />
          <span>领取营业执照</span>
        </van-grid-item>
        <van-grid-item>
          <img src="../img/Ai/icon05.png" alt="" width="60" />
          <span>完成公司刻章</span>
        </van-grid-item>
        <van-grid-item>
          <img src="../img/Ai/icon06.png" alt="" width="80" />
        </van-grid-item>
      </van-grid>
    </div>
     <!-- 点击拨打电话 -->
        <div class="call" @click="Botell">
          <img src="../assets/call.png" alt="" width="310">
        </div>
    <!-- 公司核名常见问题 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
      <span>公司核名常见问题</span>
    </div>
    <div>
      <div v-for="item in Wentxtlists" :key="item.id" class="wenbox">
        <div class="topbox">
          <i>
            <img src="../img/Gsbg/dian.png" alt="" />
          </i>
          <span>{{ item.title }}</span>
        </div>
        <div class="bottombox">
          {{ item.text }}
        </div>
      </div>
    </div>
     <!-- 点击拨打电话 -->
        <div class="call" @click="Botell">
          <img src="../assets/call.png" alt="" width="310">
        </div>
    <Bottom />
    <Fixd />
     <transition name="slide">
      <router-view />
    </transition>

  </div>
</template>
<script>
import Bottom from "@/components/Bottom.vue";
import { selchubu,selchaxun,selchaxs,seltime, AItj, AItj1 } from '@/request/api';
import Fixd from "./Fixd.vue";
import Header from "./header.vue";
export default {
  components: { Bottom, Fixd, Header },
  data() {
    return {
      value: "",
      djbs:"智远",
      rs:0,
      sl:0,

      qy:'',
      username: "",
      password: "",
      gslx:'',

      textName:'广州智远视觉科技有限公司',

      xt_list:[],
      xtym:1,
      z_xtym:34,

      zh_zh_list:['智','远'],
      xs_list:[],
      xsym:1,
      z_xsym:14077,

      AI_list:[],
      AI_gjz:'',
      AIym:1,
      z_AIym:9546,
      zs:'不限',

      Wentxtlists: [
        {
          id: 0,
          title: "公司名称核准的类型有哪些？",
          text: " 需要提供1-（1）无行政区划表述的企业名称核准；（2）无行业表述的企业名称核准；（3）冠省字样企业名称核准；（4）冠市字样企业名称核准。",
        },
        {
          id: 1,
          title: "公司核名的规则有哪些？",
          text: "（1）不能重名；（2）不能与知名公司名字混淆；（3）不能使用繁体字、数字、英文及特殊字符；（4）不能使用行业通用词汇；（5）不能使用名人字号；（6）不能带有宗教色彩。",
        },
        {
          id: 2,
          title: "公司核名需要什么资料？",
          text: " （1）全体投资人的身份证复印件；（2）注册资金的额度及全体投资人的投资比例；（3）公司名称（最好提供5个以上）、公司大概经营范围、公司注册地址、哪个区的。",
        },
        {
          id: 3,
          title: "为什么我公司核名一直不通过？",
          text: " （1）公司名字与知名商标品牌同音；（2）公司名字包含在已有公司名字当中；（3）尽量不要起两字名。",
        },
      ],
      SQlists: [
        {
          id: 0,
          image: require("@/img/Ai/icon07.png"),
          title: "与其他公司重名",
          text: "如：“华为科技” 与 “华为技术” 算重名",
        },
        {
          id: 1,
          image: require("@/img/Ai/icon08.png"),
          title: "不能与知名公司名字混淆",
          text: "如“新百度”，“新万达”不能作为公司名字",
        },
        {
          id: 2,
          image: require("@/img/Ai/icon09.png"),
          title: "不能使用繁体字、数字、英文",
          text: "只能使用简体中文作为公司名称",
        },
        {
          id: 3,
          image: require("@/img/Ai/icon10.png"),
          title: "不能使用地区名及简称",
          text: "如：上海，沪，渝",
        },
        {
          id: 4,
          image: require("@/img/Ai/icon10.png"),
          title: "触犯驰名商标",
          text: "如：万达，老干妈",
        },
        {
          id: 5,
          image: require("@/img/Ai/icon10.png"),
          title: "不能使用名人字号",
          text: "如“马云”，“刘强东”",
        },
        {
          id: 6,
          image: require("@/img/Ai/icon10.png"),
          title: "不能带有宗教色彩",
          text: "如“伊斯兰”等不能注册为公司名字",
        },
        {
          id: 7,
          image: require("@/img/Ai/icon10.png"),
          title: "不能使用行业通用词汇",
          text: "如“广州电脑科技有限公司”",
        },
      ],

      ListQuery:{
        page :1,
        limit : 10,
        zh1:''
      },
    };
  },
    created() {
      this.seltime()
      //首先获取到当前页面的地址栏信息
      var url1 = window.location.href;//获取url地址
      var obj = {};//待会用来存放参数的对象
      var reg = /\?/;//匹配从?截取
      if(url1.match(reg)) {
        //判断传入参数，以问号截取，问号后是参数
        var chars = url1.split('?')[1];
        var arr = chars.split('&');//截取&号
        //获得截取后的数组为键值对字符串
        for (var i = 0; i < arr.length; i++) {
          //保守一点确定看是否为 name=value形式
          var num = arr[i].indexOf("=");
          if (num > 0) {
            //拼接字符串
            var name = arr[i].substring(0, num);
            var value = arr[i].substr(num + 1);
            //拼接对象，并转码
            obj[decodeURIComponent(name)] = decodeURIComponent(value);
          }
        }
      }
      var iiii={}
      // console.log(obj)
      if(JSON.stringify(obj) === '{}'){
        AItj1({"zs":'',"ai_gjz":'智远'}).then((response)=>{
          this.AI_list=response.data.z_list
          this.z_AIym=response.data.total
        })
        // return ;
      }else{
        this.qy=obj.qy
        this.username=obj.zh1
        this.password=obj.hy
        this.gslx=obj.lx
        this.onSubmit()
      }
      
			},
  methods: {
    online_advice(){
      this.$GM.as()
    },
     Botell(phoneNumber){
      this.$GM.callPhone(phoneNumber)
    },
    //相同字号中页码切换
    xtym_hqym(){
      if(this.username==''){
        this.username='智远'
      }
      this.ListQuery.page=this.xtym
      this.ListQuery.zh1=this.username
      selchaxun(this.ListQuery).then((response)=>{
        this.z_xtym=response.data.xttotal
        this.xt_list=response.data.xt
      })
    },

    //相似字号中页码切换
    xsym_hqym(){
      if(this.username==''){
        this.username='智远'
      }
      this.ListQuery.page=this.xsym
      this.ListQuery.zh1=this.username
      selchaxs(this.ListQuery).then((response)=>{
        // console.log(response.data)
        this.z_xsym=response.data.xstotal
        this.xs_list=response.data.xs
      })
    },

    //AI推荐页码切换
    AIym_hqym(){
      if(this.AIym==1){
        this.AI_zhff(this.zs)
        return ;
      }
      if (this.zs==''){
        AItj({"zs":'',"ai_gjz":'智远',"page":this.AIym}).then((response)=>{
            this.AI_list=response.data.z_list
            this.z_AIym=response.data.total
          })
      }else{
        if (this.zs=='不限'){
          if(this.AI_gjz==''){
              AItj({"zs":'',"ai_gjz":'智远',"page":this.AIym}).then((response)=>{
              this.AI_list=response.data.z_list
              this.z_AIym=response.data.total
            })
          }else{
            AItj({"zs":'',"ai_gjz":this.AI_gjz,"page":this.AIym}).then((response)=>{
            this.AI_list=response.data.z_list
            this.z_AIym=response.data.total
          })
          }
        }else if (this.zs=='2个'){
          AItj({"zs":2,"ai_gjz":this.AI_gjz,"page":this.AIym}).then((response)=>{
            this.AI_list=response.data.z_list
            this.z_AIym=response.data.total
          })
        }else if (this.zs=='3个'){
          AItj({"zs":3,"ai_gjz":this.AI_gjz,"page":this.AIym}).then((response)=>{
            this.AI_list=response.data.z_list
            this.z_AIym=response.data.total
          })
        }
        else if (this.zs=='4个'){
          AItj({"zs":4,"ai_gjz":this.AI_gjz,"page":this.AIym}).then((response)=>{
            this.AI_list=response.data.z_list
            this.z_AIym=response.data.total
          })
        }
      }
    },

    onSubmit(values) {
      // console.log("submit", values);
      if (this.qy==''){
        this.qy='广州'
      }if(this.gslx==''){
        this.gslx='有限公司'
      }

      var deviceType = navigator.userAgent;//获取userAgent信息 
      var referer1 = document.referrer;//获取referrer信息 
      var url_z=document.URL
      selchubu({"zh1":this.username,"hy":this.password,"qy":this.qy,"gslx":this.gslx,"referrer":referer1,"url_z":url_z,"useragent":deviceType}).then((response)=>{
        // console.log(response)
        this.djbs=this.username
        this.textName=this.qy+this.username+this.password+this.gslx
        this.z_xtym=response.data.xttotal
        this.xt_list=response.data.xt
        this.z_xsym=response.data.xstotal
        this.xs_list=response.data.xs
        this.zh_zh_list=response.data.zh_zh_list
      })
      this.AI_gjz=this.username
      AItj1({"zs":'',"ai_gjz":this.username}).then((response)=>{
        this.AI_list=response.data.z_list
        this.z_AIym=response.data.total
      })
      this.seltime()
      this.xtym=1
      this.xsym=1
      this.AIym=1
    },

    Ai_zs(zs){
      this.zs=zs
      this.AIym=1
      this.AI_zhff(zs)
    },
    AI_zhff(zs){
					this.zs=zs
          if(this.AI_gjz==''){
            this.AI_gjz='智远'
          }
					if (zs=='不限'){
						if(this.AI_gjz==''){
							AItj1({"zs":'',"ai_gjz":'智远'}).then((response)=>{
                this.AI_list=response.data.z_list
                this.z_AIym=response.data.total
              })
						}else{
							AItj1({"zs":'',"ai_gjz":this.AI_gjz}).then((response)=>{
                this.AI_list=response.data.z_list
                this.z_AIym=response.data.total
              })
						}
					}else if (zs=='2个'){
						AItj1({"zs":2,"ai_gjz":this.AI_gjz}).then((response)=>{
              this.AI_list=response.data.z_list
              this.z_AIym=response.data.total
            })
					}else if (zs=='3个'){
						AItj1({"zs":3,"ai_gjz":this.AI_gjz}).then((response)=>{
                this.AI_list=response.data.z_list
                this.z_AIym=response.data.total
              })
					}
					else if (zs=='4个'){
						AItj1({"zs":4,"ai_gjz":this.AI_gjz}).then((response)=>{
              this.AI_list=response.data.z_list
              this.z_AIym=response.data.total
            })
					}
				},//ai字数过滤中间方法
    //AI搜索方法
    AItj1(){
      AItj1({"zs":'',"ai_gjz":this.AI_gjz}).then((response)=>{
        this.AI_list=response.data.z_list
        this.z_AIym=response.data.total
        this.AIym=1
      })
    },
    //相同字号中字号颜色标红
    showDate(val) {
    // console.log(this.bhbj)
    if (this.username ==this.djbs){
    // this.getList()
      if (
        (val||'').indexOf(this.username) !== -1 && 
        this.username!== ''
      ) {
        return val.replace(
        this.username,
        '<font color="#f00">' + this.username+ '</font>'
        )
      } else {
        return val
      }
      }else{
        return val;
      }
    },
    //相似字号中字号颜色标红
    xsshowDate(val) {
      if (this.username ==this.djbs){
        
      // this.getList()
        if (
          (val||'').indexOf(this.zh_zh_list[0]) !== -1 
        ) {
          return val.replace(
          this.zh_zh_list[0],
          '<font color="#f00">' + (this.zh_zh_list[0]) + '</font>'
          )
        } else if((val||'').indexOf(this.zh_zh_list[1]) !== -1 ){
          return val.replace(
          this.zh_zh_list[1],
          '<font color="#f00">' + (this.zh_zh_list[1]) + '</font>'
          )
        }else{
          return val
        }
      }else{
        return val;
      }
    },
    //查询访问次数
    seltime(){
      seltime().then((response)=>{
        this.rs=response.data.rs
        this.sl=response.data.zs
      })
      
    }
  },
};
</script>
<style lang="scss"  scoped>
.aitj{
  
   padding: 5px 0px;
  background-color: #fff;
  span{
    
      color: #444444;
    em{
      color: #f42121;
    }
  }
}
.biaoqianbox {
  display: flex;
  margin-top: 15px;
 .van-tag{
   margin-right: 5px;
 }
}
.chaxunbigbox {
  padding: 20px 15px;
  background-color: #fff;

  .JGbox {
    span {
      font-weight: bold;
    }
  }

  .cxtitlebox {
    display: flex;
    justify-content: space-between;
    padding: 10px 0;
    border-bottom: 1px solid #bfbfbf;
    margin-top: 20px;

    span {
      color: #444444;

      em {
        color: #f42121;
      }
    }
  }

  .cxlists1 {
    .cxtitleboxaaa {
      display: flex;
      justify-content: space-between;
      padding: 10px 0;

      span {
        color: #444444;

        em {
          color: #f42121;
        }
      }

      span:nth-child(2) {
        color: #898989;
      }
    }
  }
}

.shiitembigbox {
  background-color: #fff;
  padding-bottom: 30px;
  padding-top: 20px;
}

.shiitembox {
  display: flex;
  background-color: #fff;
  padding: 10px 20px;
  flex-wrap: wrap;
  align-items: center;
  margin: 0 auto;
  width: 320px;
  margin-top: 10px;
  box-shadow: 0 0px 4px 0 rgba(0, 0, 0, 0.3);
  margin-bottom: 20px;
  border-radius: 5px;

  .left {
    width: 50px;
  }

  .right {
    em {
      display: block;
      font-weight: bold;
      color: #4a4a4a;
    }

    span {
      display: block;
      margin-top: 5px;
      color: #6f6f6f;
    }
  }
}

.Fromebox {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;

  .jiyu {
    color: #636363;
    display: block;
    width: 90%;
    margin-top: 5px;
    line-height: 18px;
    margin: 5px auto;
  }
}

.zihaobig {
  background-color: #fff;
  padding-bottom: 30px;

  h4 {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 5px;
  }

  span {
    text-align: center;
    display: block;
    margin-bottom: 5px;
  }
}

.kelibox {
  display: flex;
  justify-content: space-between;
  padding: 0 15px;
  background-color: #fff;
  color: #636363;
  margin-bottom: 5px;

  span {
    color: #de1e1e;
  }
}

// .dzname {
//   padding: 10px 15px;
//   height: 24px;
//   line-height: 24px;
//   background-color: #fff;
//   border-bottom: 1px solid #ebedf0;
// }

.wenbox {
  padding: 20px 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;

  .topbox {
    margin-bottom: 20px;

    span {
      font-size: 14px;
      font-weight: bold;
      margin-left: 5px;
    }
  }

  .bottombox {
    line-height: 18px;
    color: #616161;
  }
}

.cleadox {
  width: 100%;
  height: 30px;
  background-color: #fff;
}

.titleicon {
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eeeeee;

  img {
    margin-right: 5px;
  }

  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
  }
}

.titlebox {
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;

  i {
    margin-right: 5px;
  }

  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
    margin-top: -4px;
  }
}

.fanweibox {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;

  span {
    margin-top: 5px;
    color: #d92119;
  }
}

.lcbigbox {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;

  .lcbox {
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: -1px;
  }
}
/* // 过渡开始之前的样式写在这里 */
.slide-enter,
.slide-leave-to {
  right: -100%;
}
/* // 过渡时的属性写在这里 */
.slide-enter-active,
.slide-leave-active {
  transition: right 0.2s;
}
/* // 过渡完成的样式写在这里 */
.slide-enter-to,
.slide-leave {
  right: 0;
}
</style>