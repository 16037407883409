<template>
  <ul>
    <li>
      <img :src="imgSrc" style="display: block" width="100%" alt="" srcset="" />
      <div class="van-ellipsis">产品名称</div>
      <div class="price">{{ 99 | RMBformat }}</div>
    </li>
  </ul>
</template>

<script>
export default {
  data() {
    return {
      imgSrc: require("@/assets/logo.png"),
    };
  },
};
</script>

<style lang = "scss" scoped>
ul{
    padding: .1rem 2%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    li{
        width: 49%;
        margin-bottom: .1rem;
        background-color: #fff;
        text-align: center;
        line-height: .3rem;
        .price{
            color:darkred;
        }
    }
}
</style>