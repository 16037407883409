import request from './request'

// // 首页的数据请求
// export const GitHomeData = () =>  request.get('/index/index')
// // 搜索栏的数据请求
// export const GitSearchPopupData = () =>  request.get('/search/index')
// //搜索栏的文本框发生变化的时候下拉框要请求都实时数据
// export const GitSearchTipsListData = params =>  request.get('/search/helper',{params})

// export const selchubu = params =>  request.post('/selchubu',{params})
// export function selchubu(data) {
//     // console.log(data)
//     // var params={"useragent":data.deviceType}
//     // ?zh='+data.zh+'&hy='+data.hy+'&qy='+data.qy+'&gslx='+data.gslx+"&referrer="+data.referrer+"&url_z="+data.url_z,
//     return request({
//       url: '/selchubu',
//       method: 'post',
//       data
//     })
//   }
export function selchubu(data) {
    return request({
        url: '/selchubu?zh='+data.zh1+'&hy='+data.hy+'&qy='+data.qy+'&gslx='+data.gslx+"&referrer="+data.referrer+"&url_z="+data.url_z,
        method: 'post',
        data
    })
}

// 相同字号页码切换接口
export function selchaxun(data) {
    return request({
        url: '/selchaxun?zh='+data.zh1,
        method: 'post',
        data
    })
}

//相似字号页码切换接口
export function selchaxs(data) {
    return request({
        url: '/selchaxs?zh='+data.zh1,
        method: 'post',
        data
    })
}

//查询访问次数
export function seltime(data) {
    return request({
        url: '/time',
        method: 'post',
        data
    })
}

//AI推荐
export function AItj1(data) {
    return request({
        url: '/AItj1?len1='+data.zs+'&name='+data.ai_gjz,
        method: 'post',
        data
    })
}

export function AItj(data) {
    if (data.zs==''){
        var url1='/AItj?name='+data.ai_gjz
    }else{
        var url1='/AItj?len1='+data.zs+'&name='+data.ai_gjz
    }
    return request({
        url: url1,
        method: 'post',
        data
    })
}

//AI推荐
export function add_xkz(data) {
    return request({
        url: '/gsgl/add_xkz',
        method: 'post',
        data
    })
}