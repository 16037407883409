import request from './request'

// 帐号密码登录接口
export function login(data) {
    return request({
        url: data.url,
        method: 'post',
        data
    })
}

// 短信登录接口
export function registers(data) {
    return request({
        url: data.url,
        method: 'post',
        data
    })
}

//修改密码接口
export function xgmm(data) {
    return request({
        url: data.url,
        method: 'post',
        data
    })
}

// 短信验证码发送接口
export function sellogin(data) {
    return request({
        url: data.url,
        method: 'post',
        data
    })
}

// 短信验证码发送接口
export function sms(data) {
    return request({
        url: data.url,
        method: 'post',
        data
    })
}
