<template>
  <div>
    <Header />
    <!-- banner -->
    <div @click="online_advice()">
      <img src="../img/Xkz/banner.png" alt="" width="100%" />
    </div>
    <!-- 我们可以办理以下许可证 -->
    <div class="cleadox"></div>
    <div class="titlebox">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>我们可以办理以下许可证</span>
    </div>
    <div class="fanweibox">
      <van-grid :column-num="3" :border="false">
        <van-grid-item v-for="item in XKZGridLists" :key="item.id">
          <img :src="item.image" alt="" width="60" />
          <span>{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 哪些行业需要办理食品经营许可证 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>哪些行业需要办理食品经营许可证</span>
    </div>
    <div class="shipingbox">
      <van-grid :column-num="3" :border="false">
        <van-grid-item v-for="item in SPGridLists" :key="item.id">
          <img :src="item.image" alt="" width="60" />
          <span>{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 食品经营许可证办理流程 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>食品经营许可证办理流程</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Xkz/tu06.png" alt="" width="350" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 公司注册完成后拿到的资料 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>公司注册完成后拿到的资料</span>
    </div>
    <div class="ZLlists">
      <van-grid :column-num="2">
        <van-grid-item v-for="item in ZLlists" :key="item.id">
          <img :src="item.image" alt="" width="160" height="160" />
          <i>
            <img src="../img/Gsbg/br.png" alt="" />
          </i>
          <span>{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 进出口权需要准备的材料 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>进出口权需要准备的材料</span>
    </div>
    <div class="cailiaobox">
      <van-grid :column-num="3" :border="false">
        <van-grid-item v-for="item in CLGridLists" :key="item.id">
          <img :src="item.image" alt="" width="60" />
          <span>{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 增值电信许可证快速办理通道 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>增值电信许可证快速办理通道</span>
    </div>
    <div class="tongdaobigbox">
      <van-form @submit="onSubmit">
        <van-field
          v-model="username"
          name="公司名称"
          label="公司名称"
          placeholder="公司名称"
          :rules="[{ required: true, message: '请输入公司名称' }]"
        />
        <van-field
          v-model="password"
          name="电话号码"
          label="电话号码"
          placeholder="电话号码"
          :rules="[{ required: true, message: '清输入电话号码' }]"
        />
        <!-- <van-field
          readonly
          clickable
          name="picker"
          :value="value"
          label="资质类型"
          placeholder="请选择资质类型"
          @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
            show-toolbar
            :columns="columns"
            @confirm="onConfirm"
            @cancel="showPicker = false"
          />
        </van-popup> -->
        <div style="margin: 16px">
          <van-button round block type="danger" native-type="submit"
            >提交</van-button
          >
        </div>
      </van-form>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 增值电信许可证快速办理通道 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>增值电信许可证快速办理通道</span>
    </div>
    <div class="TongDaoListsbox">
      <van-grid :border="false" :column-num="2">
        <van-grid-item  v-for="item in TongDaoLists" :key="item.id" class="TongDaoLists">
         
            <i>{{item.nub}}</i>
            <em>{{item.name}}</em>
         <span>{{item.text}}</span>
          
        </van-grid-item> 
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 食品经营许可证办理流程 -->
     <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>食品经营许可证办理流程</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Xkz/tu12.png" alt="" width="350" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
     <!-- 证件交付 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>证件交付</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Xkz/tu13.png" alt="" width="350" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 互联网文化、药品销售相关许可证种类 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>互联网文化、药品销售相关许可证种类</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Xkz/tu15.png" alt="" width="350" />
      </div>
      <div class="lcbox">
        <img src="../img/Xkz/tu16.png" alt="" width="350" />
      </div>
      <div class="lcbox">
        <img src="../img/Xkz/tu17.png" alt="" width="350" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 证件交付 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>证件交付</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Xkz/tu14.png" alt="" width="350" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 许可证常见问题 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
      <span>许可证常见问题</span>
    </div>
    <div>
      <div v-for="item in Wentxtlists" :key="item.id" class="wenbox">
        <div class="topbox">
          <i>
            <img src="../img/Gsbg/dian.png" alt="" />
          </i>
          <span>{{ item.title }}</span>
        </div>
        <div class="bottombox">
          {{ item.text }}
        </div>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <Fixd />
    <Bottom />
  </div>
</template>
<script>
import Bottom from "@/components/Bottom.vue";
import { add_xkz } from '@/request/api';
import Fixd from "./Fixd.vue";
import Xkz from "@/views/Xkz.vue";
import Header from "./header.vue";
export default {
  components: { Bottom, Fixd, Xkz, Header },
  data() {
    return {
      username: "",
      password: "",
      value: "",
      columns: ["杭州", "宁波", "温州", "嘉兴", "湖州"],
      showPicker: false,
      Wentxtlists: [
        {
          id: 0,
          title: "食品经营许可证办理流程是怎么样的？",
          text: "（1）签订合同；（2）准备资料；（3）提交资料；（4）现场核查；（5）工商局审批；（6）领取证件。",
        },
        {
          id: 1,
          title: "哪些行业需要办理食品经营许可证?",
          text: "商场超市、便利店、食杂店、无店铺食品经营者、食品贸易商、食品物流配送、食品集中交易市场、各类型餐厅。",
        },
        {
          id: 2,
          title: "什么类型的企业适合办理进出口权？",
          text: " 国际贸易公司、进出口公司、物流及货代公司、供应链公司、报关公司。",
        },
        {
          id: 3,
          title: "公司企业获得进出口权有什么好处？",
          text: " （1）与外商接触和交流的机会更多；（2）获得出口退税退税可得获得一笔不小的利润；（3）开设外汇帐户，方便又省钱；（4）享受政府补贴。",
        },
      ],
      XKZGridLists: [
        {
          id: 0,
          image: require("@/img/Xkz/dl1.png"),
          name: "食品经营许可证",
        },
        {
          id: 1,
          image: require("@/img/Xkz/dl2.png"),
          name: "进出口经营权",
        },
        {
          id: 2,
          image: require("@/img/Xkz/dl3.png"),
          name: "房地产中介服务资质",
        },
        {
          id: 3,
          image: require("@/img/Xkz/dl4.png"),
          name: "建筑审批",
        },
        {
          id: 4,
          image: require("@/img/Xkz/dl5.png"),
          name: "道路运输许可证",
        },
        {
          id: 5,
          image: require("@/img/Xkz/dl6.png"),
          name: "医疗器械经营许可证",
        },
        {
          id: 6,
          image: require("@/img/Xkz/dl7.png"),
          name: "增值电信许可证",
        },
        {
          id: 7,
          image: require("@/img/Xkz/dl8.png"),
          name: "互联网文化药品销售相关许可证",
        },
      ],
      SPGridLists: [
        {
          id: 0,
          image: require("@/img/Xkz/tu01.png"),
          name: "商场",
        },
        {
          id: 1,
          image: require("@/img/Xkz/tu02.png"),
          name: "便利店",
        },
        {
          id: 2,
          image: require("@/img/Xkz/tu03.png"),
          name: "餐厅",
        },
        {
          id: 3,
          image: require("@/img/Xkz/tu04.png"),
          name: "咖啡厅",
        },
        {
          id: 4,
          image: require("@/img/Xkz/tu05.png"),
          name: "其他资质",
        },
      ],
      CLGridLists: [
        {
          id: 0,
          image: require("@/img/Xkz/icon01.png"),
          name: "国际贸易公司",
        },
        {
          id: 1,
          image: require("@/img/Xkz/icon02.png"),
          name: "报关公司",
        },
        {
          id: 2,
          image: require("@/img/Xkz/icon03.png"),
          name: "物流公司",
        },
        {
          id: 3,
          image: require("@/img/Xkz/icon04.png"),
          name: "货代公司",
        },
        {
          id: 4,
          image: require("@/img/Xkz/icon05.png"),
          name: "供应链公司",
        },
        {
          id: 5,
          image: require("@/img/Xkz/icon06.png"),
          name: "供应链公司",
        },
      ],
      ZLlists: [
        {
          id: 0,
          image: require("@/img/Xkz/tu07.png"),
          name: "进出口公司",
        },
        {
          id: 1,
          image: require("@/img/Xkz/tu08.png"),
          name: "经营场所平面图方位图",
        },
        {
          id: 2,
          image: require("@/img/Xkz/tu09.png"),
          name: "注册地址",
        },
        {
          id: 3,
          image: require("@/img/Xkz/tu10.png"),
          name: "食品安全管理制度",
        },
        {
          id: 4,
          image: require("@/img/Xkz/tu11.png"),
          name: "法人身份证复印件",
        },
      ],
      Iconitembox: [
        {
          id: 0,
          image: require("@/img/Gsbg/icon01.png"),
          title: "专业团队",
          text: "专业团队办理，专业人做专业事，效率高",
        },
        {
          id: 1,
          image: require("@/img/Gsbg/icon02.png"),
          title: "风险分析",
          text: "公司起名有大数据分析，公司字号经过我们研发的大数据分析，系统过滤风险，降低侵权风险",
        },
        {
          id: 2,
          image: require("@/img/Gsbg/icon03.png"),
          title: "一站式服务",
          text: "一站式全面企业服务支持，注册公司、商标申请保护、版权登记保护、代理记账、发票托管等业务全面覆盖企业运用基本需求",
        },
        {
          id: 3,
          image: require("@/img/Gsbg/icon04.png"),
          title: "售后保障",
          text: "售后有保障，系统监管办理进度、管理员负责管理、专员负责办理、办理进度可追踪，进度有保障。让办理变得简单高效有保障",
        },
      ],
      TongDaoLists:[
        {
          id:0,
          nub:'1',
          name:"ICP许可证",
          text:'互联网信息服务业务'
        },
        {
          id:1,
          nub:'2',
          name:'EDI证',
          text:'数据处理与交易处理业务'
        },
        {
          id:2,
          nub:'3',
          name:'呼叫中心',
          text:'国内呼叫中心业务'
        },
        {
          id:3,
          nub:'4',
          name:'SP证',
          text:'移动网信息服务业务'
        },
        {
          id:4,
          nub:'5',
          name:'ISP证',
          text:'互联网接入服务服务'
        },
        {
          id:5,
          nub:'6',
          name:'IDC证',
          text:'互联网数据中心业务'
        }
      ],
    };
  },

  methods: {
    onSubmit(values) {
      console.log("submit", values);
      add_xkz({"name":values.公司名称,"phono":values.电话号码}).then((response)=>{
        if(response.data>1){
          alert("提交成功")
        }else{
          alert("提交失败")
        }
        // console.log(response.data)
      })
    },
    onConfirm(value) {
      this.value = value;
      this.showPicker = false;
    },
    //  online_advice(){
    //   this.$GM.as()
    // },
    Botell(phoneNumber){
      this.$GM.callPhone(phoneNumber)
    },
  },
};
</script>
<style lang="scss"  scoped>
.TongDaoListsbox{
  padding-bottom: 30px;
  padding-top: 20px;
  background-color: #fff;
}
.TongDaoLists{
  i{
    width: 30px;
    height: 30px;
    display: block;
    text-align: center;
    line-height: 30px;
    margin: 0 auto;
    background-color: #e61b1b;
    color: #fff;
    border-radius: 25px;
    margin-bottom: 5px;
  }
  em{
    font-style: normal;
    text-align: center;
    display: block;
    margin-bottom: 5px;
  }
  span{
     font-style: normal;
     font-style: normal;
    text-align: center;
    display: block;
    margin-bottom: 5px;
  }
}
.tongdaobigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
}
.wenbox {
  padding: 20px 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  .topbox {
    margin-bottom: 20px;
    span {
      font-size: 14px;
      font-weight: bold;
      margin-left: 5px;
    }
  }
  .bottombox {
    line-height: 18px;
    color: #616161;
  }
}
.Iconitembigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
  .Iconitembox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    padding: 10px 0;
    box-sizing: border-box;
    .left {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }
    .right {
      width: 74%;
      font-size: 14px;
      font-family: "微软雅黑";
      font-weight: bold;
      i {
        color: #d92119;
        display: inline-block;
        line-height: 24px;
        border-bottom: 1px solid #d92119;
        margin-bottom: 10px;
      }
      span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-family: "微软雅黑";
        color: #272727;
      }
    }
  }
}

.ZLlists {
  background-color: #fff;
  padding-bottom: 30px;
  i {
    margin: 10px 0;
  }
}
.cleadox {
  width: 100%;
  height: 30px;
  background-color: #fff;
}
.titleicon {
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  img {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
  }
}
.titlebox {
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  i {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
    margin-top: -4px;
  }
}
.fanweibox,
.shipingbox,
.cailiaobox {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;
  span {
    margin-top: 5px;
    text-align: center;
    line-height: 20px;
  }
}
.lcbigbox {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  .lcbox {
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: -1px;
  }
}
</style>