<template>
  <div>
    <Header />
    <!-- banner -->
    <div @click="online_advice()">
      <img src="../img/Gszx/banner.png" alt="" width="100%" />
    </div>
    <!-- 我们可以提供办理的注销类型 -->
    <div class="cleadox"></div>
    <div class="titlebox">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>我们可以提供办理的注销类型</span>
    </div>
    <div class="fanweibox">
      <van-grid :column-num="3" :border="false">
        <van-grid-item v-for="item in GSZXGridLists" :key="item.id">
          <img :src="item.image" alt="" width="60" />
          <span style="margin-top: 10px">{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 互联网文化、药品销售相关许可证种类 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>互联网文化、药品销售相关许可证种类</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Gszx/tu01.png" alt="" width="350" />
      </div>
      <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Gszx/tu02.png" alt="" width="350" />
      </div>
       <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Gszx/tu03.png" alt="" width="350" />
      </div>
       <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Gszx/tu04.png" alt="" width="350" />
      </div>
       <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Gszx/tu05.png" alt="" width="350" />
      </div>
       <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Gszx/tu06.png" alt="" width="350" />
      </div>
       <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Gszx/tu07.png" alt="" width="350" />
      </div>
       <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Gszx/tu08.png" alt="" width="350" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 选择发业  快人一步 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>选择发业  快人一步</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Gszx/tu09.png" alt="" width="350" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 进出口权需要准备的材料 -->
     <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>进出口权需要准备的材料</span>
    </div>
    <div class="fanweibox">
      <van-grid :column-num="3" :border="false">
        <van-grid-item v-for="item in CLGridLists" :key="item.id">
          <img :src="item.image" alt="" width="60" />
          <span style="margin-top: 10px">{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 注销成功有哪些证明 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>注销成功有哪些证明</span>
    </div>
     <div class="lcbigbox">
      <div class="lcbox" style="margin-top: 10px">
        <img src="../img/Gszx/tu10.png" alt="" width="350" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 我们的优势 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>我们的优势</span>
    </div>
    <van-grid :column-num="2" :border="false">
        <van-grid-item v-for="item in YSGridLists" :key="item.id">
          <img :src="item.image" alt="" width="160" />
        </van-grid-item>
      </van-grid>
      <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 公司注销常见问题 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
      <span>公司注销常见问题</span>
    </div>
    <div>
      <div v-for="item in Wentxtlists" :key="item.id" class="wenbox">
        <div class="topbox">
          <i>
            <img src="../img/Gsbg/dian.png" alt="" />
          </i>
          <span>{{ item.title }}</span>
        </div>
        <div class="bottombox">
          {{ item.text }}
        </div>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <Fixd />
    <Bottom />
  </div>
</template>
<script>
import Bottom from "@/components/Bottom.vue";
import Fixd from "./Fixd.vue";
import Header from "./header.vue";
export default {
  components: { Bottom, Fixd, Header },
  data() {
    return {
      Wentxtlists: [
        {
          id: 0,
          title: "认缴制的公司注销，需要补齐注册资金吗？",
          text: " 这个问题分两种情况，一是公司对外无欠债。公司对外没有欠债就不会涉及债务偿还问题，因此，股东也不需要把认缴的资金补齐到位，这种情况下直接申请注销就可以。二是公司对外有欠债。当公司对外有欠债时，由于公司涉及到债务偿还问题，当公司现有资产不够偿还债务的时候就需要股东补齐注册资本，来偿还外债。",
        },
        {
          id: 1,
          title: "营业执照被吊销了就可以不注销吗？",
          text: "很多人认为营业执照被吊销之后就公司就自动注销了，但实际上工商局吊销营业执照后税务局是不会自动注销的。被吊销不去注销不仅可能会遭到税务罚款，股东还会被列入税务黑名单。在当今信息如此透明的情况下，企业的异常经营信息十分方便查询，未注销企业的股东、法定代表人不仅投资新的企业会受到不良影响，而且三年内也无法再注册公司。所以公司被吊销之后一定要依法进行注销登记。",
        },
        {
          id: 2,
          title: "营业执照遗失，该怎么办理注销？",
          text: " 不少公司在领取营业执照后由于疏于保管导致营业执照遗失，而办理公司注销必须提交营业执照。对营业执照遗失的情况，登记机关为缩减办理注销时间，将营业执照补办及注销办理进行合并，企业通过国家企业信用信息公示网进行营业执照遗失公示后，持公告证明及注销材料即可办理公司注销。",
        },
        {
          id: 3,
          title: "未更换三证合一的公司注销",
          text: " 2015年10月起，为了缩短注册办理公司时间，提高市场准入效率，工商行政部门对企业工商营业执照、组织机构代码证和税务登记证等三证进行合一。而在此前办理市场登记未进行三证合一，且公司营业执照被吊销的公司，在办理公司注销时可使用市场监管部门赋予的统一社会信用代码办理，无需更换新的营业执照。",
        },
      ],
      YSGridLists:[
          {
              id:0,
              image: require("@/img/Gszx/icon07.png"),
          },
          {
              id:1,
              image: require("@/img/Gszx/icon08.png"),
          },
          {
              id:2,
              image: require("@/img/Gszx/icon09.png"),
          },
          {
              id:3,
              image: require("@/img/Gszx/icon10.png"),
          }
      ],
      GSZXGridLists: [
        {
          id: 0,
          image: require("@/img/Gszx/icon01.png"),
          name: "内资公司注销",
        },
        {
          id: 1,
          image: require("@/img/Gszx/icon02.png"),
          name: "股份公司注销",
        },
        {
          id: 2,
          image: require("@/img/Gszx/icon03.png"),
          name: "合伙企业注销",
        },
        {
          id: 3,
          image: require("@/img/Gszx/icon04.png"),
          name: "集团公司注销",
        },
        {
          id: 4,
          image: require("@/img/Gszx/icon05.png"),
          name: "合资企业注销",
        },
        {
          id: 5,
          image: require("@/img/Gszx/icon06.png"),
          name: "个人企业注销",
        }
      ],
      CLGridLists:[
          {
          id: 0,
          image: require("@/img/Gszx/CD05.png"),
          name: "公司近三年的账本、凭证",
        },
        {
          id: 1,
          image: require("@/img/Gszx/CD02.png"),
          name: "清算报告",
        },
        {
          id: 2,
          image: require("@/img/Gszx/CD03.png"),
          name: "税控器",
        },
        {
          id: 3,
          image: require("@/img/Gszx/CD04.png"),
          name: "剩余发票",
        },
        {
          id: 4,
          image: require("@/img/Gszx/CD01.png"),
          name: "公司所有证件与公章",
        }
      ],
      Iconitembox: [
        {
          id: 0,
          image: require("@/img/Dljz/dl10.png"),
          text: "发业财税十多年的沉淀，办理、经历过无数种类型的案例，可以说经验丰富",
        },
        {
          id: 1,
          image: require("@/img/Dljz/dl11.png"),
          text: "发业财税已经具备完善的组织架构，人才稳定企业各方面的培训、政策解读、客户管理等方方面面都比较成熟",
        },
        {
          id: 2,
          image: require("@/img/Dljz/dl12.png"),
          text: "理念不同，发业财税着眼未来，严抓现在。对管理的投入、对财税技术的投入不断深化，力求不断让财税变得更加简单和高效",
        },
        {
          id: 3,
          image: require("@/img/Dljz/dl13.png"),
          text: "发展层次不一样，发业财税自行研发财税管理系统，财税更加有质量和效率。在目前绝大多数代理机构还是依赖人工去记账的情景下，发业已实现更高效的代账服务，我们的会计有更多的时间和精力可以花在思考、探讨、交流、推进您的财税优化问题上。",
        },
        {
          id: 4,
          image: require("@/img/Dljz/dl14.png"),
          text: "一站式打理企业的各方面财税业务，记账、报税、发票开具托管等，实现让您轻松创业、省成本省心",
        },
      ],
    };
  },

  methods: {
      online_advice(){
      this.$GM.as()
    },
    Botell(phoneNumber){
      this.$GM.callPhone(phoneNumber)
    },
  },
};
</script>
<style lang="scss"  scoped>
.sfbigbox{
    background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
  .top{
    width: 340px;
    margin: 0 auto;
  }
  .bottom{
     width: 340px;
    margin: 0 auto;
   
    border-radius: 5px;
    border: 1px dashed red;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
     margin-top: 20px;
    i{
      display: block;
      color: #555555;
      margin-bottom: 10px;
    }
    span{
      display: block;
      color: #ff3333;
      line-height: 20px;
    }
  }
}
.zybigboxitem {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
  .zyboxitem {
    display: flex;
    width: 340px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;

    .left {
      margin-right: 5px;
    }
    .right {
      width: 300px;
      margin: 0 auto;

      line-height: 20px;
      display: block;
    }
  }
}
.liyoubigbox {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
  .liyoubox {
    background-color: #ff3333;
    box-shadow: 0 0 4px #d1d1d1;
    border-radius: 5px;
    width: 340px;
    padding: 20px;
    box-sizing: border-box;
    .liyouitem {
      i {
        color: #d92119;
        background-color: #fff;
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 25px;
        margin: 0 auto;
        text-align: center;
        line-height: 25px;
      }
      span {
        color: #fff;
        width: 300px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
        line-height: 18px;
        display: block;
      }
    }
  }
}
.chayibigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
  .chayi {
    width: 340px;
    background-color: #fff;
    margin: 0 auto;
    font-size: 14px;
    font-family: "微软雅黑";
    font-weight: bold;
    padding: 20px 10px;
    i {
      color: #d92119;
      display: inline-block;
      line-height: 24px;
      border-bottom: 1px solid #d92119;
      margin-bottom: 10px;
    }
    span {
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-family: "微软雅黑";
      color: #272727;
    }
  }
}

.jztextbigbox {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
  .jztextbox {
    width: 320px;
    padding: 50px 10px 20px;
    border: 1px solid #efefef;
    background-color: #fff;
    margin: 0 auto;
    box-shadow: 0 0 4px #d1d1d1;
    border-radius: 3px;
    position: relative;
    margin-bottom: 20px;
    span {
      position: absolute;
      top: 15px;
      left: -3px;
      display: block;
      background: url(../img/Dljz/tl.png) no-repeat;
      width: 100px;
      height: 35px;
      background-size: contain;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      line-height: 28px;
      text-align: center;
    }
    em {
      font-style: normal;
      color: #555555;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.wenbox {
  padding: 20px 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  .topbox {
    margin-bottom: 20px;
    span {
      font-size: 14px;
      font-weight: bold;
      margin-left: 5px;
    }
  }
  .bottombox {
    line-height: 18px;
    color: #616161;
  }
}
.Iconitembigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
  .Iconitembox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    padding: 10px 0;
    box-sizing: border-box;
    .left {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }
    .right {
      width: 74%;
      font-size: 14px;
      font-family: "微软雅黑";
      span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-family: "微软雅黑";
        color: #272727;
      }
    }
  }
}

.cleadox {
  width: 100%;
  height: 30px;
  background-color: #fff;
}
.titleicon {
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  img {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
  }
}
.titlebox {
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  i {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
    margin-top: -4px;
  }
}
.fanweibox {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;
  span {
    margin-top: 5px;
    text-align: center;
    line-height: 18px;
  }
}
.lcbigbox {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  .lcbox {
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: -1px;
  }
}
</style>