<template>
  <div class="">
      <div class="agtextbox">
          <h1>发业用户协议</h1>
【发业财税】APP、网站（以下称“本APP”）为【广州发业财务咨询】有限公司（以下称“本公司”）开发运营的APP、网站。本用户协议(下称“本协议”)阐述之条款和条件适用于您使用【发业财税】APP、网站所提供的各种工具和服务(下称“服务”)。<br>
1.接受条款<br>  
以任何方式进入本APP即表示您同意自己已经与本APP订立本协议，且您将自愿接受本协议的条款和条件(“条款”) 约束。本APP可随时自行全权决定更改“条款”。您应在第一次登录后仔细阅读修订后的“条款”，并有权选择停止继续使用“服务”；一旦您继续使用“服务”，则表示您已接受经修订的“条款”，当您与本APP（本公司）发生争议时，应以最新的服务协议为准。除另行明确声明外，任何使“服务”范围扩大或功能增强的新内容均受本协议约束。<br>
2.服务对象<br>
“服务”仅供能够根据相关法律订立具有法律约束力的合约的自然人或法人使用。本APP可随时自行全权决定拒绝向任何自然人或法人提供“服务”。“服务”不会提供给被暂时或永久中止资格的本APP会员。<br>
3.收费<br>
本公司保留在根据第1条通知您后，收取“服务”费用的权利。您因进行交易、向本公司获取有偿服务或接触本公司服务器而发生的所有应纳税赋，以及相关硬件、软件、通讯、网络服务及其他方面的费用均由您自行承担。本公司保留在无须发出书面通知，仅在本APP公示的情况下，暂时或永久地更改或停止部分或全部“服务”的权利。<br>
4.您的资料<br>
“您的资料”包括您在注册、交易过程中、在任何公开信息场合或通过任何电子邮件形式，向本公司或其他用户提供的任何资料，包括数据、文本、软件、音乐、声响、照片、图画、影像、词句或其他材料。您应对“您的资料”负全部责任，而本公司仅作为您在网上发布和刊登“您的资料”的被动渠道。但是，倘若本公司认为“您的资料”可能使本公司承担任何法律或道义上的责任，或可能使本公司 (全部或部分地) 失去本公司的互联网服务供应商或其他供应商的服务，或您未在本APP规定的期限内登录或再次登录网站，则本公司可自行全权决定对“您的资料”采取本公司认为必要或适当的任何行动，包括但不限于删除该类资料。您特此保证，您对提交给本APP的“您的资料”拥有全部权利，包括全部版权，不对任何第三方构成任何侵权。您确认，本APP没有责任去认定或决定您提交给本公司的资料哪些是应当受到保护的，对享有“服务”的其他用户使用“您的资料”，以及因木马、病毒、遗失密码、黑客等非因本公司原因造成的“您的资料”被他人使用，本公司也不必负责。本公司无任何义务保存“您的资料”，不对“您的资料”的丢失承担任何责任。<br>
4.1注册义务<br>
如您在本APP注册，您同意：(a) 根据会员注册页面所刊载的会员资料表格的要求，提供关于您或贵单位的真实、准确、完整和反映当前情况的资料；(b) 维持并及时更新会员资料，使其保持真实、准确、完整和反映当前情况。倘若您提供任何不真实、不准确、不完整或不能反映当前情况的资料，或本APP有合理理由怀疑该等资料不真实、不准确、不完整或不能反映当前情况，本APP有权暂停或终止您的注册身份及资料，并拒绝您在目前或将来对“服务”(或其任何部份) 以任何形式使用。如您代表一家公司或其他法律主体在本公司登记，则您声明和保证，您有权使该公司或其他法律主体受本协议“条款”约束。<br>
4.2会员注册名、密码和保密<br>
在登记过程中，您将选择会员注册名和密码。您须自行负责对您的会员注册名和密码保密，且须对您在会员注册名和密码下发生的所有活动承担责任。您同意：(a) 如发现任何人未经授权使用您的会员注册名或密码，或发生违反保密规定的任何其他情况，您会立即通知本APP；及 (b) 确保您在每个上网时段结束时，以正确步骤离开网站。本APP不能也不会对因您未能遵守本款规定而发生的任何损失或损毁负责。<br>
4.3 关于您的资料的规则<br>
您同意，“您的资料”和您供在本APP上交易的任何“物品”（泛指一切可供依法交易的、有形的或无形的、以各种形态存在的某种具体的物品，或某种权利或利益，或某种票据或证券，或某种服务或行为。本协议中“物品”一词均含此义） a. 不会有欺诈成份，与售卖伪造或盗窃无涉。 b. 不会侵犯任何第三者对该物品享有的物权，或版权、专利、商标、商业秘密或其他知识产权，或隐私权、名誉权。 c. 不会违反任何法律、法规、条例、规章或政策等 (包括但不限于关于规范出口管理、贸易配额、保护消费者、不正当竞争或虚假广告的法律、法规、条例、规章或政策等)。 d. 不会含有诽谤（包括商业诽谤）、非法恐吓或非法骚扰的内容。 e. 不会含有淫秽、或包含任何儿童色情内容。 f. 不会含有蓄意毁坏、恶意干扰、秘密地截取或侵占任何系统、数据或个人资料的任何病毒、伪装破坏程序、电脑蠕虫、定时程序炸弹或其他电脑程序。 g. 不会直接或间接与下述各项货物或服务连接，或包含对下述各项货物或服务的描述：(i) 本协议项下禁止的货物或服务；或 (ii) 您无权连接或包含的货物或服务。此外，您同意不会：(h) 在与任何连锁信件、大量胡乱邮寄的电子邮件、滥发电子邮件或任何复制或多余的信息有关的方面使用“服务”；(i) 未经其他人士或单位同意，利用“服务”收集其他人士或单位的电子邮件地址及其他资料；或 (j) 利用“服务”制作虚假的电子邮件地址，或以其他形式试图在发送人的身份或信息的来源方面误导其他人士或单位。<br>
4.3被禁止物品<br>
您不得在本公司网站公布或通过本公司网站买卖：(a) 可能使本公司违反任何相关法律、法规、条例或规章的任何物品；或 (b) 本APP认为应禁止或不适合通过本网站买卖的任何物品。<br>
5.您授予本公司的许可使用权<br>
您授予本公司独家的、全球通用的、永久的、免费的许可使用权利 (并有权在多个层面对该权利进行再授权)，使本公司有权(全部或部份地) 使用、复制、修订、改写、发布、翻译、分发、执行和展示"您的资料"或制作其派生作品，和/或以现在已知或日后开发的任何形式、媒体或技术，将"您的资料"纳入其他作品内。<br>
6. 隐私<br>
6.1会员有义务在注册时提供自己的真实资料，并保证诸如电子邮件地址、联系电话、联系地址、邮政编码等内容的有效性、真实性，若上述资料变更的，会员应及时登录在本APP的账号并进行更新，若会员在上述资料变更后未及时更新而导致本APP无法与会员取得联系致使会员在使用本服务过程中遭受的任何损失或增加任何费用等不利后果的，均由会员本人承担责任。<br>
6.2为确保会员在本APP进行交易的安全性，会员同意本网站从其他第三方获取会员的额外资料，本APP仅收集本公司认为就此目的及达成该目的所必须的关于会员的个人资料。
6.3会员同意本公司可以自行或通过合作的第三方机构对会员提交或本APP搜集的用户信息（包括但不限于用户的个人身份信息等）进行核实，并对获得的核实结果根据本协议及有关文件进行查看、使用和留存等操作。本公司可能会从合作第三方处获得用户信息以补充本APP自行收集的信息，以便于向用户提供服务，用户对此表示理解和同意。<br>
6.4会员同意本APP依靠自主研发的大数据风控系统进行风险监测，记录会员注册、登录、找回密码、实名认证、绑定银行卡、充值、提现等整个业务环节中的各类数据，通过对大量数据和用户行为分析，识别风险，保障用户账号、资金安全；对于存在安全隐患的账号和交易，本APP会通过人工审核流程，包括但不限于会员提供网银充值流水、身份证及银行卡图文资料、会员手持身份证和银行卡照片，必要时本APP还会采取与用户通过视频进行安全认证。<br>
6.5本APP在某些网页上使用诸如“Cookies”的资料收集装置。“Cookies”是设置在用户的硬盘上的小档案，以协助本APP为会员提供度身订造的服务。本APP亦提供某些只能通过使用“Cookies”才可得到的功能。本APP还利用“Cookies”使会员能够在某段期间内减少输入密码的次数。“Cookies”还可以协助本网站提供专门针对会员的兴趣而提供的资料。<br>
6.6如果会员将个人通讯信息（例如：手机短信、电邮或信件）交付本APP，或如果其他会员或第三方向本网站发出关于会员在本APP上的活动或登录事项的通讯信息，本网站可以将这些资料收集在会员的专门档案中。<br>
6.7会员同意本APP可使用关于会员的个人资料（包括但不限于本APP持有的有关会员的档案中的资料，及本APP从会员目前及以前在本APP上的活动所获取的其他资料）以解决争议、对纠纷进行调停、确保在本APP进行安全交易，并执行本APP的相关规则。为限制在本APP的欺诈、非法或其他刑事犯罪活动，使本APP免受其害，会员同意本APP可通过人工或自动程序对会员的个人资料进行评价。<br>
6.8会员同意本APP可以使用会员的个人资料以改进本APP的推广和促销工作、分析本APP的使用率、改善本APP的内容，并使本APP的网站内容、设计和服务更能贴近会员的需求。这些使用能改善本APP的网页，以调整本APP的网页使其更能贴近会员的需求，从而使会员在使用本APP服务时得到更为顺利、有效、安全及度身订造的交易体验。<br>
6.9会员同意本网站利用会员的资料与会员联络并（在某些情况下）向会员传递针对会员的兴趣而提供的信息，例如：有针对性的广告条、行政管理方面的通知、以及有关会员使用本APP的通讯。会员接受本协议即视为会员同意收取这些资料。<br>
6.10本APP对于会员提供的、自行收集到的、经认证的个人信息将按照本协议及有关规则予以保护、使用或者披露。本APP将采用行业标准惯例以保护会员的个人资料，但鉴于技术限制，本APP不能确保会员的全部私人通讯及其他个人资料不会通过本协议中未列明的途径泄露出去。<br>
6.11本APP有义务根据有关法律要求向司法机关和政府部门提供会员的个人资料。在会员未能按照与本APP签订的服务协议或者与本APP其他会员签订的协议等其他法律文本的约定履行自己应尽的义务时，本APP有权根据自己的判断，或者与该笔交易有关的其他会员的请求披露会员的个人信息和资料，并做出评论。会员严重违反本APP的相关规则的，本APP有权对会员提供的及本APP自行收集的会员的个人信息和资料编辑入网站黑名单，并将该黑名单对第三方披露，且本APP有权将会员提交或本APP自行收集的会员的个人资料和信息与任何第三方进行数据共享，由此可能造成会员的任何损失，本APP不承担法律责任。6.12本APP提供的交易活动中，会员无权要求本APP提供其他会员的个人资料。如会员通过签署有关协议等方式获得其他会员的个人信息，会员同意对于上述信息予以保密，除非该等信息根据适用的法律规定、被有管辖权的法院或政府部门要求披露。<br>
7.终止或访问限制<br>
您同意，在本APP未向您收费的情况下，本APP可自行全权决定以任何理由 (包括但不限于本APP认为您已违反本协议的字面意义和精神，或您以不符合本协议的字面意义和精神的方式行事，或您在超过90天的时间内未以您的账号及密码登录网站) 终止您的“服务”密码、账户 (或其任何部份) 或您对“服务”的使用，并删除和丢弃您在使用“服务”中提交的 “您的资料”。您同意，在本APP向您收费的情况下，本APP应基于合理的怀疑且经电子邮件通知的情况下实施上述终止服务的行为。本APP同时可自行全权决定，在发出通知或不发出通知的情况下，随时停止提供“服务”或其任何部份。您同意，根据本协议的任何规定终止您使用“服务”之措施可在不发出事先通知的情况下实施，并承认和同意，本APP可立即使您的账户无效，或撤销您的账户以及在您的账户内的所有相关资料和档案，和/或禁止您进一步接入该等档案或“服务”。账号终止后，本APP没有义务为您保留原账号中或与之相关的任何信息，或转发任何未曾阅读或发送的信息给您或第三方。此外，您同意，本APP不会就终止您接入“服务”而对您或任何第三者承担任何责任。<br>
8.服务提供<br>
本APP对下述内容不作保证：(i)“服务”会符合您的要求；(ii)“服务”不会中断，且适时、安全和不带任何错误；(iii) 通过使用“服务”而可能获取的结果将是准确或可信赖的；及 (iv) 您通过“服务”而购买或获取的任何产品、服务、资料或其他材料的质量将符合您的预期。通过使用“服务”而下载或以其他形式获取任何材料是由您自行全权决定进行的，且与此有关的风险由您自行承担，对于因您下载任何该等材料而发生的您的电脑系统的任何损毁或任何数据损失，您将自行承担责任。您从本APP或通过或从“服务”获取的任何口头或书面意见或资料，均不产生未在本协议内明确载明的任何保证。<br>
9.责任范围<br>
您明确理解和同意，本APP不对因下述任一情况而发生的任何损害赔偿承担责任，包括但不限于利润、商誉、使用、数据等方面的损失或其他无形损失的损害赔偿 (无论本APP是否已被告知该等损害赔偿的可能性)：(i) 使用或未能使用“服务”；(ii) 因通过或从“服务”购买或获取任何货物、样品、数据、资料或服务，或通过或从“服务”接收任何信息或缔结任何交易所产生的获取替代货物和服务的费用；(iii) 未经批准接入或更改您的传输资料或数据；(iv) 任何第三者对“服务”的声明或关于“服务”的行为；或 (v) 因任何原因而引起的与“服务”有关的任何其他事宜，包括疏忽。<br>
10.链接<br>
“服务”或第三者均可提供与其他互联网网站或资源的链接。由于本APP并不控制该等网站和资源，您承认并同意，本APP并不对该等外在网站或资源的可用性负责，且不认可该等网站或资源上或可从该等网站或资源获取的任何内容、宣传、产品、服务或其他材料，也不对其等负责或承担任何责任。您进一步承认和同意，对于任何因使用或信赖从此类网站或资源上获取的此类内容、宣传、产品、服务或其他材料而造成（或声称造成）的任何直接或间接损失，本APP均不承担责任。<br>
11.通知<br>
除非另有明确规定，任何通知应以电子邮件形式发送，或 (就您而言) 发送到您在登记过程中向本APP提供的电子邮件地址，或有关方指明的该等其他地址。在电子邮件发出二十四 (24) 小时后，通知应被视为已送达，除非发送人被告知相关电子邮件地址已作废。或者，本公司可通过邮资预付挂号邮件并要求回执的方式，将通知发到您在登记过程中向本APP提供的地址。在该情况下，在付邮当日三 (3) 天后通知被视为已送达。<br>
12.不可抗力<br>
对于因本公司合理控制范围以外的原因，包括但不限于自然灾害、罢工或骚乱、物质短缺或定量配给、暴动、战争行为、政府行为、通讯或其他设施故障或严重伤亡事故等，致使本公司延迟或未能履约的，本APP不对您承担任何责任。<br>
13.转让<br>
本APP转让本协议无需经您同意。<br>
14.其他规定<br>
本协议取代您和本APP先前就相同事项订立的任何书面或口头协议。倘若本协议任何规定被裁定为无效或不可强制执行，该项规定应被撤销，而其余规定应予执行。条款标题仅为方便参阅而设，并不以任何方式界定、限制、解释或描述该条款的范围或限度。本公司未就您或其他人士或单位的某项违约行为采取行动，并不表明本公司撤回就任何继后或类似的违约事件采取行动的权利。<br>
15.诉讼<br>
因本协议或本公司服务所引起或与其有关的任何争议应向广州市天河区人民法院提起诉讼，并以中华人民共和国法律为管辖法律。<br>
      </div>
  </div>
</template>
<script>

export default {

  data() {
    return {
     
    };
  },

  methods: {
   
  },
};
</script>
<style lang="scss" scoped>
.agtextbox{
    line-height: 25px;
    padding: 40px 20px;
    font-family: '微软雅黑';
    h1{
        font-size: 25px;
        text-align: center;
        margin: 0px auto 20px;
    }
}
</style>