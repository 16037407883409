<template>
  <div>
    <Header />
    <!-- banner -->
    <div  @click="online_advice()">
      <img src="../img/Gsbg/banner.png" alt="" width="100%" />
    </div>
    <!-- 变更服务范围 -->
    <div class="cleadox"></div>
    <div class="titlebox">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>变更服务范围</span>
    </div>
    <div class="fanweibox">
      <van-grid :column-num="3" :border="false">
        <van-grid-item v-for="item in GSBGGridLists" :key="item.id">
          <img :src="item.image" alt="" width="60" />
          <span>{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 变更流程 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>变更流程</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Gsbg/tu01.png" alt="" width="350" />
      </div>
      <div class="lcbox">
        <img src="../img/Gsbg/tu02.png" alt="" width="350" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 公司注册完成后拿到的资料 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>公司注册完成后拿到的资料</span>
    </div>
    <div class="ZLlists">
      <van-grid :column-num="2">
        <van-grid-item v-for="item in ZLlists" :key="item.id">
          <img :src="item.image" alt="" width="160" height="160" />
          <i>
            <img src="../img/Gsbg/br.png" alt="" />
          </i>
          <span>{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 我们的优势 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>我们的优势</span>
    </div>
    <div class="Iconitembigbox">
      <div class="Iconitembox" v-for="item in Iconitembox" :key="item.id">
        <div class="left">
          <img :src="item.image" alt="" width="72px" />
        </div>
        <div class="right">
          <i>{{ item.title }}</i>
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 公司变更常见问题 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
      <span>公司变更常见问题</span>
    </div>
    <div>
      <div v-for="item in Wentxtlists" :key="item.id" class="wenbox">
        <div class="topbox">
          <i>
            <img src="../img/Gsbg/dian.png" alt="" />
          </i>
          <span>{{ item.title }}</span>
        </div>
        <div class="bottombox">
          {{ item.text }}
        </div>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <Fixd />
    <Bottom />
  </div>
</template>
<script>
import Bottom from '@/components/Bottom.vue';
import Fixd from './Fixd.vue';
import Header from './header.vue';
export default {
    components:{ Bottom, Fixd, Header },
  data() {
    return {
      Wentxtlists: [
        {
          id: 0,
          title: "变更公司名称需要哪些资料？",
          text: " 需要提供1-2新公司名称、营业执照正副本、公章、股东和法人身份证。",
        },
        {
          id: 1,
          title: "一般公司满多久才能变更?",
          text: "一般需要满一年才能进行公司变更。",
        },
        {
          id: 2,
          title: "公司跨区变更是怎么样的？",
          text: " 跨区变更需要迁移工商和税务档案，流程更加繁琐和复杂。",
        },
        {
          id: 3,
          title: "公司变更之后还要做什么事情吗？",
          text: " 还需变更银行信息、税务报到、变更社保、变更商标等信息。",
        },
      ],
      GSBGGridLists: [
        {
          id: 0,
          image: require("@/img/Gsbg/dl1.png"),
          name: "公司地址变更",
        },
        {
          id: 1,
          image: require("@/img/Gsbg/dl2.png"),
          name: "公司法人变更",
        },
        {
          id: 2,
          image: require("@/img/Gsbg/dl3.png"),
          name: "公司股东变更",
        },
        {
          id: 3,
          image: require("@/img/Gsbg/dl4.png"),
          name: "经营范围变更",
        },
        {
          id: 4,
          image: require("@/img/Gsbg/dl5.png"),
          name: "注册资金变更",
        },
        {
          id: 5,
          image: require("@/img/Gsbg/dl6.png"),
          name: "公司名称变更",
        },
        {
          id: 6,
          image: require("@/img/Gsbg/dl7.png"),
          name: "地址异常解锁",
        },
        {
          id: 7,
          image: require("@/img/Gsbg/dl8.png"),
          name: "其他变更",
        },
      ],
      ZLlists: [
        {
          id: 0,
          image: require("@/img/Gsbg/tu03.png"),
          name: "营业执照正副本",
        },
        {
          id: 1,
          image: require("@/img/Gsbg/tu04.png"),
          name: "公司印章",
        },
        {
          id: 2,
          image: require("@/img/Gsbg/tu05.png"),
          name: "公司章程",
        },
        {
          id: 3,
          image: require("@/img/Gsbg/tu06.png"),
          name: "开业通知书",
        },
        {
          id: 4,
          image: require("@/img/Gsbg/tu07.png"),
          name: "法人身份证复印件",
        },
      ],
      Iconitembox: [
        {
          id: 0,
          image: require("@/img/Gsbg/icon01.png"),
          title: "专业团队",
          text: "专业团队办理，专业人做专业事，效率高",
        },
        {
          id: 1,
          image: require("@/img/Gsbg/icon02.png"),
          title: "风险分析",
          text: "公司起名有大数据分析，公司字号经过我们研发的大数据分析，系统过滤风险，降低侵权风险",
        },
        {
          id: 2,
          image: require("@/img/Gsbg/icon03.png"),
          title: "一站式服务",
          text: "一站式全面企业服务支持，注册公司、商标申请保护、版权登记保护、代理记账、发票托管等业务全面覆盖企业运用基本需求",
        },
        {
          id: 3,
          image: require("@/img/Gsbg/icon04.png"),
          title: "售后保障",
          text: "售后有保障，系统监管办理进度、管理员负责管理、专员负责办理、办理进度可追踪，进度有保障。让办理变得简单高效有保障",
        },
      ],
    };
  },

  methods: {
     online_advice(){
      this.$GM.as()
    },
    Botell(phoneNumber){
      this.$GM.callPhone(phoneNumber)
    },
  },
};
</script>
<style lang="scss"  scoped>
.wenbox{
    padding: 20px 20px;
    background-color: #fff;
    border-bottom: 1px solid #efefef;
    .topbox{
        margin-bottom: 20px;
        span{
            font-size: 14px;
            font-weight: bold;
            margin-left: 5px;
        }
    }
    .bottombox{
        line-height: 18px;
        color: #616161;
    }
}
.Iconitembigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
  .Iconitembox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    padding: 10px 0;
    box-sizing: border-box;
    .left {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }
    .right {
      width: 74%;
      font-size: 14px;
      font-family: "微软雅黑";
      font-weight: bold;
      i {
        color: #d92119;
        display: inline-block;
        line-height: 24px;
        border-bottom: 1px solid #d92119;
        margin-bottom: 10px;
      }
      span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-family: "微软雅黑";
        color: #272727;
      }
    }
  }
}

.ZLlists {
  background-color: #fff;
  padding-bottom: 30px;
  i {
    margin: 10px 0;
  }
}
.cleadox {
  width: 100%;
  height: 30px;
  background-color: #fff;
}
.titleicon {
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  img {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
  }
}
.titlebox {
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  i {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
    margin-top: -4px;
  }
}
.fanweibox {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;
  span {
    margin-top: 5px;
  }
}
.lcbigbox {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  .lcbox {
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: -1px;
  }
}
</style>