<template>
    <div class="ysbox">
        <div>
            <van-nav-bar title="隐私政策" left-text="返回" left-arrow @click-left="onClickLeft" />
        </div>
        <YS />
    </div>
</template>
<script>
import YS from '@/components/YS.vue';
export default {
    data() {
        return {};
    },
    methods: {
        onClickLeft() {
            this.$router.go(-1);
        }
    },
    components: { YS }
};
</script>
<style lang="scss" scoped>
.ysbox {
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    background-color: #fff;
    overflow-y: scroll;
}
</style>