<template>
  <div>
    <Header />
    <!-- banner -->
    <div @click="online_advice()">
      <img src="../img/Dljz/banner.png" alt="" width="100%" />
    </div>
    <!-- 我们可以提供的财税服务 -->
    <div class="cleadox"></div>
    <div class="titlebox">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>我们可以提供的财税服务</span>
    </div>
    <div class="fanweibox">
      <van-grid :column-num="3" :border="false">
        <van-grid-item v-for="item in DLJZGridLists" :key="item.id">
          <img :src="item.image" alt="" width="60" />
          <span>{{ item.name }}</span>
        </van-grid-item>
      </van-grid>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 代理记账服务流程 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>代理记账服务流程</span>
    </div>
    <div class="lcbigbox">
      <div class="lcbox">
        <img src="../img/Dljz/tu01.png" alt="" width="350" />
      </div>
      <div class="lcbox">
        <img src="../img/Dljz/tu02.png" alt="" width="350" />
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 哪些企业更加适合选择代理记账 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>哪些企业更加适合选择代理记账</span>
    </div>
    <div class="jztextbigbox">
      <div class="jztextbox">
        <span>初创型企业</span>
        <em>
          请一个专业知识全面财务成本高昂，对初创型企业来说，资金是发展最重要的因素，选择代理可以享受规范的财税服务，省钱又省心
        </em>
      </div>
      <div class="jztextbox">
        <span>发展型企业</span>
        <em>
          对于有一定发展型阶段的企业来说，组织架构逐步清晰。如果需要实现规范的财务管理需要投入的财务人才成本也不低，这时候可以考虑专职财务搭配代理记账来实现投入成本低又可以很好满足企业发展对财务管理的需求
        </em>
      </div>
      <div class="jztextbox">
        <span>成熟型企业</span>
        <em>
          成熟型企业各方面都比较完善，这个阶段往往可以结合代理记账来分担基础性的工作也不失为一种好策略，让专职财务更加专注于企业的财务管理
        </em>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 代理记账公司之间的差异 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>代理记账公司之间的差异</span>
    </div>
    <div class="chayibigbox">
      <div class="chayi">
        <i>初创型的代理公司</i>
        <span
          >人员不足，各方面的条件不成熟，这个阶段的代理公司提供的服务保障性会比较弱一点</span
        >
      </div>
      <div class="chayi">
        <i>有一定规模，但又达不到完善的代理公司</i>
        <span
          >知识面、规范化、效率、管理等方面问题在这个层次的公司会比较严重</span
        >
      </div>
      <div class="chayi">
        <i>规模大的代理公司</i>
        <span
          >这种情况的公司好处是接触的案例多，选择这类的企业不
          仅是享受直接对接的人才资源，同时也可以在享受整个代
          理公司所沉淀的知识资源，解决问题会比较容易</span
        >
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 选择发业财税的理由 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>选择发业财税的理由</span>
    </div>
    <div class="Iconitembigbox">
      <div class="Iconitembox" v-for="item in Iconitembox" :key="item.id">
        <div class="left">
          <img :src="item.image" alt="" width="60px" />
        </div>
        <div class="right">
          <span>{{ item.text }}</span>
        </div>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 选择发业财税的理由 -->
    <div class="titlebox" style="margin-top: 10px">
      <i>
        <img src="../img/Gsbg/lr.jpg" alt="" />
      </i>
      <span>选择发业财税的理由</span>
    </div>
    <div class="liyoubigbox">
      <div class="liyoubox">
        <div class="liyouitem">
          <i>1</i>
          <span
            >发业智能财税管理系统自动监控企业数据变化很好做到简单复杂账务的分类管理和精准追踪财税问题的解决</span
          >
        </div>
        <div class="liyouitem">
          <i>2</i>
          <span
            >我们的账务管理分明，对不同情境的账务实施不同级别的管理。很好做到重点把控、严格把控重要问题得到重点管理。</span
          >
        </div>
        <div class="liyouitem">
          <i>3</i>
          <span
            >我们多年实践经验积累丰富的知识库、应对各种涉税问题有很好的基础</span
          >
        </div>
        <div class="liyouitem">
          <i>4</i>
          <span
            >智能涉税任务管理和质量管理系统，很好保障不漏申报、不错报、风险预警、问题追踪管理问题清晰化简单化。这个也是发业对代理记账的深度发展最求目标，提供优质可靠的财税服务。</span
          >
        </div>
        <div class="liyouitem">
          <i>5</i>
          <span
            >有专业专门的涉税研究小组，及时根据税务环境变化深入学习。对重点难点涉税业务进行介入诊断、把控、优化。这个完全不同传统记账模式，传统记账模式过渡依赖人手，财务人员没有充足时间和清晰的目标不断深化财税业务的提升。发业已实现大数据时代智能化管理加对专业的重点管理想结合。让负责的财税业务尽可能享受优质的财税服务。</span
          >
        </div>
        <div class="liyouitem">
          <i>6</i>
          <span
            >发业服务团队分明，有专门负责财税进行整体把控的会计岗位，同时为客户提供一站式服务比如发票开具及托管服务。实现专业岗位做专业事，每个岗位很好地聚焦工作本身，专业度不断提升，精力也得到充分有效的发挥。传统代理模式岗位不明晰，一个会计负责全面所有事项。这样的团队非常难把控每个会计的专业全面性和深度。</span
          >
        </div>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 重要事项 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
      <span>重要事项</span>
    </div>
    <div class="zybigboxitem">
      <div class="zyboxitem">
        <div class="left">
          <img src="../img/Gsbg/dian.png" alt="" width="12" />
        </div>
        <div class="right">
          一、必须要及时纳税申报，交税和报税是两码事。很多企业主会理解只要不经营没有收入，没有税需要交不用报税，这个是非常常见的情景。无收入的情景也要进行零申报，不报税逾期申报将会产生罚款。
        </div>
      </div>
      <div class="zyboxitem">
        <div class="left">
          <img src="../img/Gsbg/dian.png" alt="" width="12" />
        </div>
        <div class="right">
          二、企业银行账号也叫基本户要及时到银行开好户，以免影响企业纳税申报和员工社保申报
        </div>
      </div>
      <div class="zyboxitem">
        <div class="left">
          <img src="../img/Gsbg/dian.png" alt="" width="12" />
        </div>
        <div class="right">
          三、企业资金流管理需要注意税收问题，资金流不合规管理可能会导致更加高的税负成本。比如股东和企业之间的资金往来不规范，两者之间的资金流混为一起难以划分等。其中存在税务重大问题是被视为分红所产生的高额个人所得税之外，还会存在企业账目在混乱的情况下将以最高适用税率为征收标准的补征风险。
        </div>
      </div>
      <div class="zyboxitem">
        <div class="left">
          <img src="../img/Gsbg/dian.png" alt="" width="12" />
        </div>
        <div class="right">
          四、在现行的环境下，财税合规是企业必然之路。在大数据时代，不合格的财税管理给企业带来的负担是毁灭性。了解税务合法管理涉税业务不但可以解除税务风险同时也可以很好合法降低税务成本。
        </div>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <!-- 开办企业到底需要交哪些税和费 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
      <span>开办企业到底需要交哪些税和费</span>
    </div>
    <div class="sfbigbox">
      <div class="top">
        <img src="../img/Dljz/tu03.png" alt="" width="340" />
      </div>
      <div class="bottom">
        <i>还有部分企业会产生</i>
        <span>消费税，文化事业建设费（常见广告业、娱乐行业等），房产税，契税，土地增值税（常见购买公寓等房产业务），车辆购置税，车船税（涉及购买车辆），资源税和环境保护税，城镇土地使用税和耕地占用税，烟叶税</span>
      </div>
    </div>
    <!-- 代理记账常见问题 -->
    <div class="titleicon" style="margin-top: 10px">
      <img src="../img/home/Ticon02.png" alt="" width="25px" height="25px" />
      <span>代理记账常见问题</span>
    </div>
    <div>
      <div v-for="item in Wentxtlists" :key="item.id" class="wenbox">
        <div class="topbox">
          <i>
            <img src="../img/Gsbg/dian.png" alt="" />
          </i>
          <span>{{ item.title }}</span>
        </div>
        <div class="bottombox">
          {{ item.text }}
        </div>
      </div>
    </div>
    <!-- 点击拨打电话 -->
    <div class="call" @click="Botell">
      <img src="../assets/call.png" alt="" width="310">
    </div>
    <Fixd />
    <Bottom />
  </div>
</template>
<script>
import Bottom from "@/components/Bottom.vue";
import Fixd from "./Fixd.vue";
import Header from "./header.vue";
export default {
  components: { Bottom, Fixd, Header },
  data() {
    return {
      Wentxtlists: [
        {
          id: 0,
          title: "一般纳税人需要交纳什么税？",
          text: " 一般常见的税种是增值税和企业所得税，个人所得税和附加税。",
        },
        {
          id: 1,
          title: "刚开公司需要什么时候申报税吗？",
          text: "一般来说公司成立后，建议三个月后走基本流水，零申报建议不要超过一年。",
        },
        {
          id: 2,
          title: "我自己做账不行吗？",
          text: " 如果自己不是会计，又不懂这一行，需要跑地税局和相关抵扣工作，费力又费时。",
        },
        {
          id: 3,
          title: "需要提交哪些发票做账？",
          text: " 专业的会计人员主动联系并说明需要提供做账的发票。",
        },
      ],
      DLJZGridLists: [
        {
          id: 0,
          image: require("@/img/Dljz/dl1.png"),
          name: "代理记账",
        },
        {
          id: 1,
          image: require("@/img/Dljz/dl2.png"),
          name: "税务咨询",
        },
        {
          id: 2,
          image: require("@/img/Dljz/dl3.png"),
          name: "开票托管",
        },
        {
          id: 3,
          image: require("@/img/Dljz/dl4.png"),
          name: "汇算年检",
        },
        {
          id: 4,
          image: require("@/img/Dljz/dl5.png"),
          name: "一般纳税人申请",
        },
        {
          id: 5,
          image: require("@/img/Dljz/dl6.png"),
          name: "执照年检",
        },
        {
          id: 6,
          image: require("@/img/Dljz/dl7.png"),
          name: "财务审计",
        },
        {
          id: 7,
          image: require("@/img/Dljz/dl8.png"),
          name: "员工社保个税打理",
        },
        {
          id: 8,
          image: require("@/img/Dljz/dl9.png"),
          name: "其他服务",
        },
      ],
      Iconitembox: [
        {
          id: 0,
          image: require("@/img/Dljz/dl10.png"),
          text: "发业财税十多年的沉淀，办理、经历过无数种类型的案例，可以说经验丰富",
        },
        {
          id: 1,
          image: require("@/img/Dljz/dl11.png"),
          text: "发业财税已经具备完善的组织架构，人才稳定企业各方面的培训、政策解读、客户管理等方方面面都比较成熟",
        },
        {
          id: 2,
          image: require("@/img/Dljz/dl12.png"),
          text: "理念不同，发业财税着眼未来，严抓现在。对管理的投入、对财税技术的投入不断深化，力求不断让财税变得更加简单和高效",
        },
        {
          id: 3,
          image: require("@/img/Dljz/dl13.png"),
          text: "发展层次不一样，发业财税自行研发财税管理系统，财税更加有质量和效率。在目前绝大多数代理机构还是依赖人工去记账的情景下，发业已实现更高效的代账服务，我们的会计有更多的时间和精力可以花在思考、探讨、交流、推进您的财税优化问题上。",
        },
        {
          id: 4,
          image: require("@/img/Dljz/dl14.png"),
          text: "一站式打理企业的各方面财税业务，记账、报税、发票开具托管等，实现让您轻松创业、省成本省心",
        },
      ],
    };
  },

  methods: {
     online_advice(){
      this.$GM.as()
    },
    Botell(phoneNumber){
      this.$GM.callPhone(phoneNumber)
    },
  },
};
</script>
<style lang="scss"  scoped>
.sfbigbox{
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
  .top{
    width: 340px;
    margin: 0 auto;
  }
  .bottom{
     width: 340px;
    margin: 0 auto;
   
    border-radius: 5px;
    border: 1px dashed red;
    margin: 0 auto;
    padding: 10px;
    box-sizing: border-box;
     margin-top: 20px;
    i{
      display: block;
      color: #555555;
      margin-bottom: 10px;
    }
    span{
      display: block;
      color: #ff3333;
      line-height: 20px;
    }
  }
}
.zybigboxitem {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
  .zyboxitem {
    display: flex;
    width: 340px;
    margin: 0 auto;
    margin-top: 10px;
    margin-bottom: 20px;

    .left {
      margin-right: 5px;
    }
    .right {
      width: 300px;
      margin: 0 auto;

      line-height: 20px;
      display: block;
    }
  }
}
.liyoubigbox {
  display: flex;
  justify-content: center;
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
  .liyoubox {
    background-color: #ff3333;
    box-shadow: 0 0 4px #d1d1d1;
    border-radius: 5px;
    width: 340px;
    padding: 20px;
    box-sizing: border-box;
    .liyouitem {
      i {
        color: #d92119;
        background-color: #fff;
        display: block;
        width: 25px;
        height: 25px;
        border-radius: 25px;
        margin: 0 auto;
        text-align: center;
        line-height: 25px;
      }
      span {
        color: #fff;
        width: 300px;
        margin: 0 auto;
        margin-top: 10px;
        margin-bottom: 20px;
        line-height: 18px;
        display: block;
      }
    }
  }
}
.chayibigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
  .chayi {
    width: 340px;
    background-color: #fff;
    margin: 0 auto;
    font-size: 14px;
    font-family: "微软雅黑";
    font-weight: bold;
    padding: 20px 10px;
    i {
      color: #d92119;
      display: inline-block;
      line-height: 24px;
      border-bottom: 1px solid #d92119;
      margin-bottom: 10px;
    }
    span {
      display: block;
      font-size: 14px;
      line-height: 20px;
      font-family: "微软雅黑";
      color: #272727;
    }
  }
}

.jztextbigbox {
  background-color: #fff;
  padding-top: 20px;
  padding-bottom: 30px;
  .jztextbox {
    width: 320px;
    padding: 50px 10px 20px;
    border: 1px solid #efefef;
    background-color: #fff;
    margin: 0 auto;
    box-shadow: 0 0 4px #d1d1d1;
    border-radius: 3px;
    position: relative;
    margin-bottom: 20px;
    span {
      position: absolute;
      top: 15px;
      left: -3px;
      display: block;
      background: url(../img/Dljz/tl.png) no-repeat;
      width: 100px;
      height: 35px;
      background-size: contain;
      color: #fff;
      font-size: 14px;
      font-weight: bold;
      line-height: 28px;
      text-align: center;
    }
    em {
      font-style: normal;
      color: #555555;
      font-size: 12px;
      line-height: 16px;
    }
  }
}

.wenbox {
  padding: 20px 20px;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  .topbox {
    margin-bottom: 20px;
    span {
      font-size: 14px;
      font-weight: bold;
      margin-left: 5px;
    }
  }
  .bottombox {
    line-height: 18px;
    color: #616161;
  }
}
.Iconitembigbox {
  padding-top: 10px;
  padding-bottom: 30px;
  background-color: #fff;
  .Iconitembox {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-around;
    background-color: #fff;
    padding: 10px 0;
    box-sizing: border-box;
    .left {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      align-items: center;
    }
    .right {
      width: 74%;
      font-size: 14px;
      font-family: "微软雅黑";
      span {
        display: block;
        font-size: 14px;
        line-height: 20px;
        font-family: "微软雅黑";
        color: #272727;
      }
    }
  }
}

.cleadox {
  width: 100%;
  height: 30px;
  background-color: #fff;
}
.titleicon {
  padding: 15px 20px;
  box-sizing: border-box;
  background-color: #fff;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: 1px solid #eeeeee;
  img {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
  }
}
.titlebox {
  padding: 5px 10px;
  padding-left: 20px;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  background-color: #fff;
  border-bottom: 1px solid #efefef;
  i {
    margin-right: 5px;
  }
  span {
    font-size: 18px;
    font-weight: bold;
    font-family: "微软雅黑";
    margin-top: -4px;
  }
}
.fanweibox {
  background-color: #fff;
  padding-top: 10px;
  padding-bottom: 20px;
  span {
    margin-top: 5px;
  }
}
.lcbigbox {
  padding-top: 20px;
  padding-bottom: 30px;
  background-color: #fff;
  .lcbox {
    background-color: #fff;
    display: flex;
    justify-content: center;
    margin-top: -1px;
  }
}
</style>