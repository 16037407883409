<template>
  <div class="Gszcbox">
    <GSZC />
  </div>
</template>
<script>
import GSZC from "@/components/GSZC.vue";
export default {
  components: { GSZC },
  data() {
    return {};
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
.Gszcbox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: #efefef;
  overflow-y: scroll;
}
</style>