<template>
  <div class="">
      <div class="agtextbox">
          <h1>发业隐私政策</h1>
【发业财税】APP、网站（以下称“本APP”或“我们”）为【广州发业财务咨询】有限公司（以下称“本公司”）开发运营的APP。，我们尊重并保护您的隐私。您使用我们的服务时，我们将按照本隐私权政策（以下简称“本政策”）收集、存储、使用及对外提供您的个人信息。<br>
为了遵守国家法律法规及监管规定（例如：进行实名制管理、履行反洗钱职责、采取风险防范措施），也为了向您提供服务及提升服务质量（例如：支持我们开发新产品或完善已有产品功能，为您提供和推荐更为优质或适合的服务），我们需要收集、存储、使用及对外提供您的信息。您同意我们按照本政策约定处理您的信息，以便您享受优质、便捷、个性化的服务，同时更好地保护您的账户及资金安全。<br>
一、 您的个人信息<br>
个人信息是指以电子或者其他方式记录的能够单独或者与其他信息结合识别特定自然人身份或者反映特定自然人活动情况的各种信息。<br>
在您使用本APP服务的过程中，在以下情形下我们需要收集您的个人信息。您并非必须向我们提供个人信息，但如果您选择不提供，我们可能无法为您提供相关服务，或者无法回应或解决您所遇到的问题。<br>
（一）注册本APP账号<br>
如果您仅需使用基础本地服务，您可以选择不进行注册及提供以下信息。如果要享受主要功能或服务，需要您注册本APP账号（即会员账号）。当您注册本APP账号（即会员账号）时，为满足相关法律法规规定的网络实名制要求，我们会要求您提供相应个人信息，如您的姓名、身份证号码、手机号码、创建的账号和密码、手势密码、电子邮箱等。您提供的上述信息，将在您使用本APP服务期间持续授权本APP服务方使用。在您注销账号后，我们将停止使用上述信息。<br>
 (二) 交易管理服务<br>
当您使用本APP服务时，您需进行银行账户开立、绑定、充值、提现、投资、还款、缴费等操作，本APP服务方及/或委托的银行、第三方支付机构将收集您的姓名、身份证件信息、银行账号信息、手机号码，并对您的身份信息及银行账户信息进行验证。<br>
本APP服务方及/或委托的银行、第三方支付机构将收集您的投资记录、提现记录、借款记录、还款记录、缴费记录，收集此类信息是为了达到资金结算、账务核对、信息报告提供等目的，以及满足相关法律法规的信息披露要求。<br>
(三) 用户管理服务<br>
我们会收集您的设备信息，以及您和您的设备如何与本APP服务交互的信息，此类信息包括：<br>
1. 设备及应用信息：设备名称、设备识别码、应用程序版本等。<br>
2. 日志信息：当您使用本APP服务或者查看由本APP提供的内容时，本APP会自动收集某些信息并存储在日志中，如服务的访问时间、访问次数、访问IP、事件信息（如错误、崩溃、重启、升级）等。<br>
(四) 服务升级和个性化推荐<br>
为提升您的服务体验及改进服务质量，或者为您推荐更优质或更适合的服务：<br>
1. 您可以授权提供您的位置信息，以便您基于所在地点位置接受个性化服务推荐；<br>
2. 我们还将收集非识别性信息，即无法用于确定个人身份的信息，例如网站访问量、APP下载量、产品销量、地理位置等。我们会收集汇总的统计信息，目的在于了解用户如何使用我们的服务。借此，我们可以改善自己的服务，更好地满足用户需求。我们可能会自行决定为其他目的收集、使用、披露、转移非识别性信息。<br>
(五) 根据相关法律法规，在以下情形中，我们可能会依法收集并使用您的个人信息无需征得您的同意:<br>
1. 与国家安全、国防安全直接相关的；<br>
2. 与公共安全、公共卫生、重大公共利益直接相关的；<br>
3. 与犯罪侦查、起诉、审判和判决执行等直接相关的；<br>
4. 出于维护您或他人的生命、财产等重大合法权益目的但又很难得到您本人同意的;<br>
5. 所收集的个人信息是您自行向社会公众公开的；<br>
6. 从合法公开披露的信息中收集个人信息，例如：合法的新闻报道、政府信息公开等渠道；<br>
7. 根据您的要求签订和履行合同所必需的；<br>
8. 用于维护所提供的服务的安全稳定运行所必需的，例如：发现、处置产品或服务的<br>
9. 法律法规规定的其他情形。<br>
(六) 其他<br>
我们在向您提供其他业务功能时，会另行向您说明信息收集的范围与目的，并征得您的同意后方收集提供相应服务所必要的您的信息。我们会按照本政策以及相应的用户协议约定使用、存储、对外提供及保护您的信息；如您选择不提供前述信息，不会影响您使用支付服务。<br>
二、Cookie技术<br>
为使您获得更轻松的访问体验，您访问本APP网站或使用本APP提供的服务时，我们可能会通过小型数据文件识别您的身份，这么做可帮您省去重复输入注册信息的步骤，或者帮助判断您的账户安全状态。这些数据文件可能是Cookie，Flash Cookie，您的浏览器或关联应用程序提供的其他本地存储（以下简称“Cookie”）。请您理解，我们的某些服务只能通过使用Cookie才可得到实现。如您的浏览器或浏览器附加服务允许，您可以修改对Cookie的接受程度或者拒绝本APP的Cookie。多数浏览器工具条中的“帮助”部分会告诉您怎样防止您的浏览器接受新的Cookie，怎样让您的浏览器在您收到一条新Cookie时通知您或者怎样彻底关闭Cookie。此外，您可以通过改变浏览器附加程序的设置，或通过访问提供商的网页，来关闭或删除浏览器附加程序使用的类似数据（例如：Flash Cookie）。但这一举动在某些情况下可能会影响您安全访问本APP网站和使用本APP提供的服务。<br>
三、存储和保护个人信息<br>
(一) 我们在中华人民共和国境内收集和产生的个人信息将存储在中华人民共和国境内。如部分产品涉及跨境业务，我们需要向境外机构传输境内收集的相关个人信息的，我们会按照法律法规和相关监管部门的规定执行，并通过签订协议、现场核查等有效措施，要求境外机构为所获得的您的个人信息保密。我们仅在本政策所述目的所必需期间和法律法规及监管规定的时限内保存您的个人信息。<br>
(二) 我们承诺我们将使信息安全保护达到法律、法规、监管规定及行业标准的要求。为保障您的信息安全，我们致力于使用各种安全技术及配套的管理体系来尽量降低您的信息被泄露、毁损、误用、非授权访问、非授权披露和更改的风险。例如：通过网络安全层软件（SSL）进行加密传输、信息加密存储、严格限制数据中心的访问、使用专用网络通道及网络代理。同时我们设立了个人信息保护责任部门，建立了相关内控制度，对可能接触到您的信息的工作人员采取最小够用授权原则；对工作人员处理您的信息的行为进行系统监控，不断对工作人员培训相关法律法规及隐私安全准则和安全意识强化宣导，并每年组织全体工作人员参加安全考试。另外，我们每年还会聘请外部独立第三方对我们的信息安全管理体系进行评估。<br>
(三) 我们已制定个人信息安全事件应急预案，定期组织内部相关人员进行应急响应培训和应急演练，使其掌握岗位职责和应急处置策略和规程。在不幸发生个人信息安全事件后，我们将按照法律法规的要求，及时向您告知：安全事件的基本情况和可能的影响、我们已采取或将要采取的处置措施、您可自主防范和降低风险的建议、对您的补救措施等。我们将及时将事件相关情况以APP推送通知、发送邮件/短消息等方式告知您，难以逐一告知个人信息主体时，我们会采取合理、有效的方式发布公告。同时，我们还将按照监管部门要求，主动上报个人信息安全事件的处置情况。若您的合法权益受损，我们将承担相应的法律责任。<br>
(四) 请您务必妥善保管好您的本APP登录名及其他身份要素。您在使用本APP服务时，我们会通过您的登录名及其他身份要素来识别您的身份。一旦您泄漏了前述信息，您可能会蒙受损失，并可能产生对您不利的法律后果。如您发现本APP登录名及/或其他身份要素可能或已经泄露时，请您立即和我们取得联系，以便我们及时采取相应措施以避免或降低相关损失。<br>
(五) 在您终止使用本APP服务后，我们会停止对您的信息的收集和使用，法律法规或监管部门另有规定的除外。如我们停止运营，我们将及时停止收集您个人信息的活动，将停止运营的通知以逐一送达或公告的形式通知您，并对所持有的您的个人信息进行删除或匿名化处理。<br>
四、对外提供个人信息<br>
(一) 共享<br>
1. 业务共享<br>
我们承诺对您的信息进行保密。除法律法规及监管部门另有规定外，我们仅在以下情形中与第三方共享您的信息，第三方包括我们的关联公司、合作金融机构以及其他合作伙伴。在将信息提供给第三方前，我们将尽商业上合理的努力评估该第三方收集信息的合法性、正当性、必要性。我们会与第三方签订相关法律文件并要求第三方处理您的个人信息时遵守法律法规，尽力要求第三方对您的信息采取保护措施。<br>
（1）为了让您完成交易、实现交易目的，我们需要向您的交易相对方提供交易号与相关支付信息，同时您的交易相对方可在付款过程中或付款成功后查看您的部分注册信息；<br>
（2）某些产品或服务可能由第三方提供或由我们与第三方共同提供，因此，只有共享您的信息，才能提供您需要的产品或服务。例如：您通过本APP购买金融产品时，我们需要向合作金融机构提供您的有效证件信息与联系方式，以保证您完成购买流程合规性要求以及保证您的资产准确无误的登记；<br>
（3）事先获得您明确同意的情况下，我们会在法律法规允许且不违背公序良俗的范围内，依据您的授权范围与第三方共享您的信息。<br>
2. 投诉处理<br>
当您投诉他人或被他人投诉时，为了保护您及他人的合法权益，维护投资环境，我们可能会将您的姓名及有效证件号码、联系方式、投诉相关内容提供给相关监管机关，以便及时解决投诉纠纷，但法律法规明确禁止提供的除外。<br>
(二) 转让<br>
我们不会将您的个人信息转让给任何公司、组织和个人，但以下情况除外：<br>
（1）事先获得您的明确同意；<br>
（2）根据法律法规或强制性的行政或司法要求；<br>
（3）在涉及资产转让、收购、兼并、重组或破产清算时，如涉及到个人信息转让，我们会向您告知有关情况，并要求新的持有您个人信息的公司、组织继续受本政策的约束。如变更个人信息使用目的时，我们将要求该公司、组织重新取得您的明确同意。<br>
(三) 公开披露<br>
除在公布中奖活动名单时会脱敏展示中奖者手机号或本APP登录名外，原则上我们不会将您的信息进行公开披露。如确需公开披露时，我们会向您告知公开披露的目的、披露信息的类型及可能涉及的敏感信息，并征得您的明确同意。<br>
(四) 委托处理<br>
为了提升信息处理效率，降低信息处理成本，或提高信息处理准确性，我们可能会委托有能力的我们的关联公司或其他专业机构代表我们来处理用户信息，但我们会通过书面协议、现场审计等方式要求受托公司遵守严格的保密义务及采取有效的保密措施，禁止其将这些信息用于未经您授权的用途。在委托关系解除时，受托公司不再保存个人信息。我们承诺对此负责。<br>
(五) 根据相关法律法规及国家标准，在以下情形中，我们可能会依法共享、转让、公开披露您的个人信息无需征得您的同意：<br>
（1）与国家安全、国防安全直接相关的；<br>
（2）与公共安全、公共卫生、重大公共利益直接相关的；<br>
（3）与犯罪侦查、起诉、审判和判决执行等直接相关的；<br>
（4）出于维护您或其他个人的生命、财产等重大合法权益但又很难得到您本人同意的；<br>
（5）您自行向社会公众公开的个人信息；<br>
（6）从合法公开披露的信息中收集的个人信息，例如：合法的新闻报道、政府信息公开等渠道。<br>
五、访问和管理自己的个人信息<br>
在您使用本APP服务期间，为了您可以更加便捷地访问和管理您的个人信息，同时保障您注销账户的权利，我们在产品设计中为您提供了相应的操作设置，您可以参考下面的指引进行操作。<br>
(一) 管理您的个人信息<br>
您可通过本APP登录本APP账户：<br>
1. 查阅您的手机、邮箱绑定情况，微信等第三方账号授权情况，以及进行相应的解绑操作。<br>
2. 如发现您的信息有误，您可以根据页面提示自助修改您的信息。<br>

您也可通过本APP登录本APP账户：<br>
1. 查阅您的交易记录以及绑定的银行存管信息。为了您的个人信息安全，您的身份证号码和银行账户会进行脱敏展示；<br>
2. 如发现您的信息有误，您可以根据页面提示自助修改您的信息，您也可以联系我们的客服热线或通过在线客服要求更正。出于安全性和身份识别的考虑或根据法律法规及监管规定，您需通过验证方可修改您已认证的姓名或已绑定的手机号码；<br>
3. 您可管理绑定的银行卡。<br>
(二) 您可通过如下路径注销本APP账户：<br>
当您符合约定的账户注销条件并注销某本APP账户后，您该账户内的所有信息将被清空，我们将不会再收集、使用或对外提供与该账户相关的个人信息，但您在使用本APP服务期间提供或产生的信息我们仍需按照监管要求的时间进行保存，且在该依法保存的时间内有权机关仍有权依法查询。<br>
(三) 如您发现我们收集、使用您个人信息的行为，违反了法律法规规定或违反了与您的约定，您可联系我们的客服热线或通过在线客服，要求删除相应的信息。<br>
(四) 尽管有上述约定，但按照相关法律法规及国家标准，在以下情形中，我们可能无法响应您的请求：<br>
1. 与国家安全、国防安全直接相关的；<br>
2. 与公共安全、公共卫生、重大公共利益直接相关的；<br>
3. 与犯罪侦查、起诉、审判和执行判决等直接相关的；<br>
4. 有充分证据表明您存在主观恶意或滥用权利的；<br>
5. 响应您的请求将导致其他个人、组织的合法权益受到严重损害的；<br>
6. 涉及商业秘密的。<br>
六、对第三方责任的声明<br>
请您注意，您的交易相对方、您访问的第三方网站经营者、通过我们接入的第三方服务和由本APP处接收您的个人信息的第三方可能有自己的隐私权保护政策；当您查看第三方创建的网页或使用第三方开发的应用程序时，这些第三方可能会放置他们自己的Cookie或像素标签，这些Cookie或像素标签不受我们的控制，且它们的使用不受本政策的约束。我们会尽商业上的合理努力去要求这些主体对您的个人信息采取保护措施，但我们无法保证这些主体一定会按照我们的要求采取保护措施，请您与他们直接联系以获得关于他们的隐私权政策的详细情况。如您发现这些第三方创建的网页或第三方开发的应用程序存在风险时，建议您终止相关操作以保护您的合法权益。<br>
七、 本政策的适用及更新<br>
本APP所有服务均适用本政策。<br>
发生下列重大变化情形时，我们会适时对本政策进行更新：
（一）我们的基本情况发生变化，例如：兼并、收购、重组引起的所有者变更；<br>
（二）收集、存储、使用个人信息的范围、目的、规则发生变化；<br>
（三）对外提供个人信息的对象、范围、目的发生变化；<br>
（四）您访问和管理个人信息的方式发生变化；<br>
（五）数据安全能力、信息安全风险发生变化；<br>
（六）用户询问、投诉的渠道和机制，以及外部纠纷解决机构及联络方式发生变化；<br>
（七）其他可能对您的个人信息权益产生重大影响的变化。<br>
由于本APP的用户较多，如本政策发生更新，我们将以APP推送通知、发送邮件/短消息或者在本APP官方网站发布公告的方式来通知您。为了您能及时接收到通知，建议您在联系方式更新时及时通知我们。如您在本政策更新生效后继续使用本APP服务，即表示您已充分阅读、理解并接受更新后的政策并愿意受更新后的政策约束。<br>
八、 如何联系我们<br>
如果您对本政策有任何疑问、意见或建议，请通过产品的客服热线与我们联系。通常情况下，我们将在30天之内解答您的疑问。<br>
      </div>
  </div>
</template>
<script>

export default {

  data() {
    return {
     
    };
  },

  methods: {
   
  },
};
</script>
<style lang="scss" scoped>
.agtextbox{
    line-height: 25px;
    padding: 40px 20px;
    font-family: '微软雅黑';
    h1{
        font-size: 25px;
        text-align: center;
        margin: 0px auto 20px;
    }
}
</style>