<template>
  <div class="Gszxbox">
    <GSZX />
  </div>
</template>
<script>
import GSZX from "@/components/GSZX.vue";
export default {
  components: { GSZX },
  data() {
    return {};
  },

  methods: {},
};
</script>
<style lang="scss" scoped>
.Gszxbox {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  background-color: #efefef;
  overflow-y: scroll;
}
</style>