import { render, staticRenderFns } from "./GSZX.vue?vue&type=template&id=605b5e09&scoped=true&"
import script from "./GSZX.vue?vue&type=script&lang=js&"
export * from "./GSZX.vue?vue&type=script&lang=js&"
import style0 from "./GSZX.vue?vue&type=style&index=0&id=605b5e09&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "605b5e09",
  null
  
)

export default component.exports