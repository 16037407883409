<template>
    <div>
        <div class="loginbg">
            <img src="../assets/dubg.png" alt="" width="100%">
            <div class="logincenterbox">
                <div class="box">
                    <div class="logotu">
                        <img src="../assets/logodu.png" alt="" width="149">
                    </div>
                    <div class="purposebox">
                        <div class="rightBOX">
                            <div id="rootform" v-show="divxs == 0">
                                <div class="menuLIst">
                                    <ul>
                                        <li v-for="(item, index) in list" :key="item.id"
                                            :class="{ active: num == index }" @click="getNum(index)">
                                            {{ item }}
                                            <span></span>
                                        </li>
                                    </ul>
                                </div>
                                <div class="tabCon">
                                    <div v-show="num == 0">
                                        <van-form @submit="onSubmit">
                                            <van-field v-model="username" name="用户名" label="用户名" placeholder="请输入您的手机号 "
                                               />
                                            <van-field v-model="password" type="password" name="密码" label="密码"
                                                placeholder="密码" />
                                            <div v-show="show_name_pwd">{{err_name_pwd}}</div>
                                            <div class="textbox">
                                                <van-checkbox v-model="checkbox" checked-color="#ff2828" shape="square" /> 记住密码
                                            </div>
                                            <!-- <div class="textbox">
                                                <van-checkbox v-model="xycheckbox" checked-color="#ff2828" shape="square" />同意 <span
                                                    @click="$router.push('./SearchPopup')">《发业用户协议》</span> 及<span
                                                    @click="$router.push('./SearchPopup')">《发业隐私权政策》</span>
                                            </div> -->
                                            <div style="margin: 16px;">
                                                <van-button round block type="danger" native-type="submit">提交
                                                </van-button>
                                            </div>
                                            <div @click="wjmm()" class="forget">忘记密码？</div>
                                            <div class="forget">
                                                <img src="../assets/wx.png" alt="" width="37">
                                            </div>
                                        </van-form>
                                    </div>
                                    <div v-show="num == 1">
                                        <van-form @submit="onSubmit_phono_yzm">
                                            <van-field v-model="EWMusername" @blur="check_username()" name="用户名" label="用户名" placeholder="请输入您的手机号 "
                                               />
                                            <van-field v-model="YZMneber" type="text" name="验证码" label="验证码"
                                                />
                                            <div style="line-height: 40px;margin-left: 15px;" @click="send_sms_code()">
                                               {{sms_code_message}}
                                            </div>
                                            <div style="line-height: 40px;margin-left: 15px;" v-show="show_phono_yzm">
                                                {{err_phono_yzm}}
                                            </div>
                                            <div class="textbox" v-show="show_xychekbox">
                                                <van-checkbox v-model="xycheckbox" checked-color="#ff2828" shape="square" />同意 <span
                                                    @click="$router.push('./agreement')">《发业用户协议》</span> 及<span
                                                    @click="$router.push('./yingsi')">《发业隐私权政策》</span>
                                            </div>
                                            <div style="margin: 16px;">
                                                <van-button round block type="danger" native-type="submit">提交
                                                </van-button>
                                            </div>
                                           
                                            <div class="forget">
                                                <img src="../assets/wx.png" alt="" width="37">
                                            </div>
                                        </van-form>
                                    </div>
                                </div>
                            </div>
                            <div id="rootform" v-show="divxs == 1">
                                <div style=" margin-left: 15px;  margin-bottom: 40px;">
                                    找回密码
                                </div>
                                <div>
                                    <van-form @submit="wjmm_Submit">
                                            <van-field v-model="ZHusername" @blur="check_wjusername()" name="用户名" label="用户名" placeholder="请输入您的手机号 "
                                                />
                                            <van-field v-model="ZHneber" type="text" name="验证码" label="验证码"
                                                placeholder="验证码" />
                                            <van-field v-model="ZHpassword" type="password" name="密码" label="密码"
                                                placeholder="密码" />
                                            <div style="line-height: 40px;margin-left: 15px;" @click="send_sms_code_xg()"> 
                                                {{wjsms_code_message}}
                                            </div>
                                            <div v-show="show_wjmm" style="line-height: 40px;margin-left: 15px;">{{err_wjmm}}</div>
                                            <div style="margin: 16px;">
                                                <van-button round block type="danger" native-type="submit">确认
                                                </van-button>
                                            </div>
                                           
                                            <div style="text-align: center;" @click="fanhuei()">
                                                返回登录
                                            </div>
                                        </van-form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        
    </div>
</template>
<script>
import {login, registers, sellogin, sms, xgmm} from  '@/request/login'
export default {

    data() {
        return {
            ZHusername:'',      //找回表单中帐号
            ZHneber:'',         //找回表单中验证码
            ZHpassword:'',      //找回表单中密码
            
            EWMusername: '',    //验证码表单中手机号码
            YZMneber:'',        //验证码表单中短信验证码

            username: '',       //帐号密码表单中用户名
            password: '',       //帐号密码表单中密码
            num: 0,             //帐号密码吧--验证码表单切换标识
            divxs: 0,           //忘记密码 --登录界面表单切换标识

            err_name_pwd:'',    //帐号密码表单中错误提示信息
            err_phono_yzm:'',   //验证码表单中错误提示信息
            err_wjmm:'',        //忘记密码表单中错误提示信息
            sms_code_message:'获取验证码',//获取验证码以及倒计时显示
            wjsms_code_message:'获取验证码',//获取验证码以及倒计时显示

            list: ["密码登录", "验证码登陆/注册"],

            show_name_pwd:false,//帐号密码表单中错误提示是否显示
            show_phono_yzm:false,//验证码表单中错误提示是否显示
            show_wjmm:false,     //忘记密码表单中错误提示是否显示
            show_xychekbox:false,//协议弹窗是否显示

            checkbox: false,    //记住密码勾选框
            xycheckbox: false,  //协议勾选框
            send_flag:false,    //短信验证码便签，避免用户恶意点击
            wjsend_flag:false,    //短信验证码便签，避免用户恶意点击
        };
    },

    methods: {
        //清空数据方法
        qksj(){
            this.ZHusername=''     //找回表单中帐号
            this.ZHneber=''         //找回表单中验证码
            this.ZHpassword=''     //找回表单中密码
            
            this.EWMusername=''   //验证码表单中手机号码
            this.YZMneber=''      //验证码表单中短信验证码

            this.username=''       //帐号密码表单中用户名
            this.password=''      //帐号密码表单中密码
            this.err_name_pwd=''   //帐号密码表单中错误提示信息
            this.err_phono_yzm=''  //验证码表单中错误提示信息
            this.err_wjmm=''     //忘记密码表单中错误提示信息
            this.sms_code_message='获取验证码'//获取验证码以及倒计时显示
            this.wjsms_code_message='获取验证码'//获取验证码以及倒计时显示
            this.show_name_pwd=false//帐号密码表单中错误提示是否显示
            this.show_phono_yzm=false//验证码表单中错误提示是否显示
            this.show_wjmm=false     //忘记密码表单中错误提示是否显示
            this.show_xychekbox=false//协议弹窗是否显示

            this.checkbox=false  //记住密码勾选框
            this.xycheckbox=false  //协议勾选框
            this.send_flag=false   //短信验证码便签，避免用户恶意点击
            this.wjsend_flag=false   //短信验证码便签，避免用户恶意点击
        },
        //验证码表单中发送验证码
        send_sms_code(){
            if (this.send_flag==true){//判断门是否关的
                return ;//有人在拉屎 门是关的 先返回
            }else{
                this.send_flag=true//你进去拉屎了 把门关了
                let url='/admin/users/sms?mobles='+this.EWMusername;
                var params={"url":url}
                sms(params).then((response)=>{
                    if(response.code==20000){//展示倒计时60秒
                        let num=60;
                        //初始化定时器
                        let t=setInterval(()=>{
                            if (num ==1) {//倒计时即将结束
                                clearInterval(t);//停止回调函数的执行
                                this.sms_code_message='获取验证码';//还原sms_code_message
                                this.send_flag=false;
                            }else {
                                num -=1;
                                this.sms_code_message=num+'秒';
                            }
                        },1000)
                    }else{
                        this.err_phono_yzm=response.data
                        this.show_phono_yzm=true
                    }
                })
            }
        },

        //忘记密码表单发送短信验证码
        send_sms_code_xg(){
            if(this.show_wjmm==true){
                return ;
            }else{
                if (this.wjsend_flag==true){//判断门是否关的
                    return ;//有人在拉屎 门是关的 先返回
                }else{
                    this.wjsend_flag=true//你进去拉屎了 把门关了
                    let url='/admin/users/sms?mobles='+this.ZHusername;
                    var params={"url":url}
                    sms(params).then((response)=>{
                        if(response.code==20000){//展示倒计时60秒
                            let num=60;
                            //初始化定时器
                            let t=setInterval(()=>{
                                if (num ==1) {//倒计时即将结束
                                    clearInterval(t);//停止回调函数的执行
                                    this.wjsms_code_message='获取验证码';//还原sms_code_message
                                    this.wjsend_flag=false;
                                }else {
                                    num -=1;
                                    this.wjsms_code_message=num+'秒';
                                }
                            },1000)
                        }else{
                            this.err_wjmm=response.data
                            this.show_wjmm=true
                        }
                    })
                }
            }
        },

        //帐号密码登录
        onSubmit(){
            var deviceType = navigator.userAgent;//获取userAgent信息 
            // console.log(window.location.host,111)
            deviceType =String(deviceType)
            var url='/admin/users/login?mobles='+this.username+'&pwd='+this.password;
            var params={"useragent":deviceType,"url":url}
            login(params).then((response)=>{
                if(response.code==20000){
                    this.$cookies.set("token", response.data, {expires: "7D"});
                    this.$router.push({path:'/'});
                }else{
                    this.err_name_pwd=response.data
                    this.show_name_pwd=true
                }
                
            })
        },

        //短信验证登录
        onSubmit_phono_yzm(){
            var deviceType = navigator.userAgent;//获取userAgent信息 
            var url='/admin/users/registers?mobles='+this.EWMusername+'&yzm='+this.YZMneber;
            var params={"useragent":deviceType,"url":url}
            registers(params).then((response)=>{
                // console.log(response)
                if(response.code==20000){
                    this.$cookies.set("token", response.data, {expires: "7D"});
                    this.$router.push({path:'/'});
                    this.show_phono_yzm=false
                }else{
                    this.err_phono_yzm=response.data
                    this.show_phono_yzm=true
                }	
            })
        },

        //忘记密码表单中提交方法
        wjmm_Submit(){
            if(this.ZHusername==''){
                alert("请填写手机号码")
            }else if(this.ZHneber==''){
                alert("请填写验证码")
            }else if(this.ZHpassword==''){
                alert("请填写密码")
            }else{
                let url='/admin/users/xgmm?mobles='+this.ZHusername+'&yzm='+this.ZHneber+'&pwd='+this.ZHpassword;
                var params={"url":url}
                xgmm(params).then((response)=>{
                    console.log(response)
                     if(response.code==20000){
                        this.$cookies.set("token", response.data, {expires: "7D"});
                        this.$router.push({path:'/'});
                        this.show_wjmmw_=false
                    }else{
                        this.err_wjmm=response.data
                        this.show_wjmmw_=true
                    }		
                })
            }
        },
        // 验证码表单中校验手机格式以及是否注册过
        check_username(){
            var myreg = /^[1][3,5,7,8][0-9]{9}$/;
            if(this.EWMusername.length>10){
                if (!myreg.test(this.EWMusername)) {
                    this.show_phono_yzm=true
                    this.err_phono_yzm='请输入正确的手机格式'
                }
                else{
                    this.show_phono_yzm=false
                    this.err_phono_yzm=''
                    let url='/admin/users/sellogin?mobles='+this.EWMusername
                    var params={"url":url}
                    sellogin(params).then((response)=>{
                         if(response.code==20000){
                             this.err_phono_yzm=''
                             this.xycheckbox=true
                             this.show_phono_yzm=false
                             this.show_xychekbox=false
                         }else{
                             this.err_phono_yzm='该帐号没有注册，请勾选协议'
                             this.xycheckbox=true
                             this.show_phono_yzm=true
                             this.show_xychekbox=true
                         }
                    })
                }
            }
            else{
                this.show_phono_yzm=true
                this.err_phono_yzm='请输入正确的手机格式'
            }
        },

        // 忘记密码表单中校验手机格式以及是否注册过
        check_wjusername(){
            var myreg = /^[1][3,5,7,8][0-9]{9}$/;
            if(this.ZHusername.length>10){
                if (!myreg.test(this.ZHusername)) {
                    this.show_wjmm=true
                    this.err_wjmm='请输入正确的手机格式'
                }
                else{
                    this.show_wjmm=false
                    this.err_wjmm=''
                    let url='/admin/users/sellogin?mobles='+this.ZHusername
                    var params={"url":url}
                    sellogin(params).then((response)=>{
                         if(response.code==20000){
                             this.err_wjmm=''
                             this.show_wjmm=false
                         }else{
                             this.err_wjmm='该帐号没有注册，请先注册'
                             this.show_wjmm=true
                         }
                    })
                }
            }
            else{
                this.show_wjmm=true
                    this.err_wjmm='请输入正确的手机格式'
            }
        },

        getNum(index) {
            this.num = index;
            this.qksj()
            // console.log(this.divxs)
        },
        wjmm() {
            this.divxs = 1
            this.qksj()
            // console.log(this.divxs)
        },
        fanhuei() {
            this.divxs = 0
            this.qksj()
            // console.log(this.divxs)
        },
    },
};
</script>
<style lang="scss" scoped>
.loginbg {
    width: 100%;
    height: 100%;
    background-color: #fff;
    position: fixed;
    top: 0;
    .logincenterbox {
        width: 100%;
        height: 100%;
        background: rgba(255, 255, 255, 0);
        position: fixed;
        top: 50px;
        .box {
            width: 340px;
            height: 100%;
            margin: 0 auto;
            .logotu {
                margin: 20px;
            }
            .purposebox {
                width: 100%;
                padding: 30px 0;
                height: 35x0px;
                background-color: #fff;
                border-radius: 12px;
                box-shadow: 0 0px 15px 0 rgba(0, 0, 0, 0.3);
                .rightBOX {
                    .menuLIst {
                        ul {
                            display: flex;
                            li {
                                width: 100%;
                                text-align: center;
                                font-size: 18px;
                                height: 30px;
                                line-height: 30px;
                                color: #282828;
                                font-weight: 540;
                            }
                            li.active {
                                color: red;
                                span {
                                    display: block;
                                    width: 30px;
                                    height: 4px;
                                    border-radius: 10px;
                                    background-color: red;
                                    margin: 0 auto;
                                }
                            }
                        }
                    }
                    .tabCon {
                        padding: 20px 0 0px 0px;
                        .textbox {
                            margin-top: 20px;
                            display: flex;
                            align-items: center;
                            flex-wrap: wrap;
                            .van-checkbox {
                                margin-right: 10px;
                                padding-left: 15px;
                            }
                            span {
                                color: #f01f1f;
                            }
                        }
                        .forget{
                            text-align: center;
                            margin-top: 30px;
                        }
                    }
                }
            }
        }
    }
}
</style>