<template>
    <div>
        <div class="headerbox" >
            <img src="../assets/herder.png" alt="" width="370" @click="Botell"/>
        </div>
    </div>
</template>
<script>
export default {

    data() {
        return {
            cookie_name:'',
        };
    },
    created(){
        // setInterval(this.$GM.kefu(),2000);
        
        if(this.$cookies.isKey('token')==false){
        this.cookie_name=''
        }else{
        this.cookie_name=this.$cookies.get('token') 
        }
    },
    methods: {
        Botell(phoneNumber) {
            this.$GM.callPhone(phoneNumber)
        },
        ClickBtn(){
            console.log('1111111111')
        },
    },
};
</script>
<style lang="scss" scoped>
.headerbox{
    width: 100%;
    background-color: #7c1111;
    padding: 15px 8px;
    box-sizing: border-box;
}
</style>